import React from 'react'

import { Header } from '@root/components/molecules/Header/Component'
import { Account, RentalOrder } from '@root/generated/graphql-request'
import { hasValue } from '@root/misc/helpers'
import { listRentalOrders } from '@root/services/apiService'
import { Loader } from '@root/components/atoms/Loader/Component'
import { useNavigate } from 'react-router-dom'
import { PageContent } from '@root/components/molecules/PageContent/Component'
import { RentalOrderCard } from '@root/components/molecules/RentalOrderCard/Component'
import style from './styles.module.css'

export default function Component (): JSX.Element {
  const [rentalOrders, setRentalOrders] = React.useState<RentalOrder[]>([])
  const [account, setAccount] = React.useState<Account>()
  const [loading, setLoading] = React.useState<boolean>(true)
  const navigate = useNavigate()

  const fetchRentalOrders = React.useCallback(async () => {
    setLoading(true)

    const apiRentalOrders = await listRentalOrders()
    if (hasValue(apiRentalOrders)) {
      setRentalOrders(apiRentalOrders as RentalOrder[])
      setAccount(apiRentalOrders[0].customer)
    }

    setLoading(false)
  }, [setRentalOrders])

  React.useEffect(() => {
    void fetchRentalOrders()
  }, [fetchRentalOrders])

  React.useEffect(() => {
    if (hasValue(rentalOrders) && rentalOrders.length === 1) {
      const customerSite = rentalOrders[0]
      navigate(`/verhuurorder/${customerSite.id}`)
    }
  }, [rentalOrders, navigate])

  if (loading) {
    return (<Loader />)
  }

  const getNumberOfUnits = (): number => rentalOrders.reduce((accumulator, val: RentalOrder) => accumulator + val.numberOfActiveUnits, 0)
  const getNumberOfArtObjects = (): number => rentalOrders.reduce((accumulator, val: RentalOrder) => accumulator + val.numberOfActiveArtObjects, 0)

  const isActive = (rentalOrder: RentalOrder): boolean => rentalOrder.numberOfActiveArtObjects > 0
  const isInactive = (rentalOrder: RentalOrder): boolean => rentalOrder.numberOfActiveArtObjects === 0

  return (
    <>
      <Header
        type='multiple-locations'
        account={account as Account}
        numberOfUnits={getNumberOfUnits()}
        numberOfArtObjects={getNumberOfArtObjects()}
      />
      <PageContent title='Verhuurorders'>
        <div className={style['custom-grid']}>
          {
            rentalOrders
              .filter(isActive)
              .map((rentalOrder: RentalOrder) => {
                return (
                  <div key={rentalOrder.id} className={style['customer-site']}>
                    <RentalOrderCard rentalOrder={rentalOrder} amountImages={6} />
                  </div>
                )
              })
          }
        </div>
      </PageContent>
      {
        rentalOrders.filter(isInactive).length > 0 && (
          <PageContent title='Inactief'>
            <div className={style['custom-grid']}>
              {
                rentalOrders
                  .filter(isInactive)
                  .map((rentalOrder: RentalOrder) => {
                    return (
                      <div key={rentalOrder.id} className={style['customer-site']}>
                        <RentalOrderCard rentalOrder={rentalOrder} amountImages={6} />
                      </div>
                    )
                  })
              }
            </div>
          </PageContent>
        )
      }
    </>
  )
}
