import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import style from './style.module.css'
import { RentalOrderLine } from '@root/generated/graphql-request'
import { hasValue, flipDateFormat, formatDecimal, artSize } from '@root/misc/helpers'
import { artLocationDescription } from '@root/components/organisms/pages/CustomerSitePage/Component'
import { Box, Chip, TableSortLabel } from '@mui/material'
import { sortBy, get } from 'lodash'
import { ArtImage } from '@root/components/atoms/ArtImage/Component'
import { useNavigate } from 'react-router-dom'

const actionChip = (action: string): JSX.Element => {
  switch (action) {
    case 'Geplaatst':
      return <Chip label='Geplaatst' color='primary' variant='outlined' />

    case 'Geverifieerd':
      return <Chip label='Geverifieerd' color='success' variant='outlined' />

    case 'Geretourneerd':
      return <Chip label='Geretourneerd' color='error' variant='outlined' />

    case 'Verplaatst':
      return <Chip label='Verplaatst' color='warning' variant='outlined' />

    default:
      return <></>
  }
}

// In the initial load the sort is slightly different
// last action as 'Geretouneerd' as first value, so none aphabetical
const lastActionPrio = (obj: RentalOrderLine): number => (get(obj, 'lastAction.type') === 'Geretourneerd') ? 0 : 1

interface Props {
  rentalOrderLines: RentalOrderLine[]
  historyStatus: string
  rentalOrderId: string
}

interface SortInfo {field: string, direction: 'asc' | 'desc'}

export const RentalOrderHistory = (props: Props): JSX.Element => {
  const [items, setItems] = React.useState<RentalOrderLine[]>([])
  const [sortInfo, setSortInfo] = React.useState<SortInfo>({ field: 'none', direction: 'asc' })

  const navigate = useNavigate()

  React.useEffect(() => {
    // If no sorting is active, set sorting to lastAction
    // else if sorting is active, keep sorting state when new set of items is send by parent
    if (sortInfo.field === 'none') {
      setItems(sortBy(props.rentalOrderLines, ['lastAction.actionDate', lastActionPrio]).reverse())
      setSortInfo({ field: 'lastAction.actionDate', direction: 'desc' })
    } else {
      if (sortInfo.direction === 'asc') {
        setItems(sortItems(sortInfo.field, props.rentalOrderLines))
      } else {
        setItems(sortItems(sortInfo.field, props.rentalOrderLines).reverse())
      }
    }
  }, [props.rentalOrderLines])

  const sortItems = (key: string, coll: any[]) => (key.endsWith('actionDate'))
    ? sortBy(coll, (obj) => new Date(get(obj, key)).getTime())
    : sortBy(coll, (obj) => get(obj, key))

  const sortRentalOrders = (key: string): void => {
    const { field, direction } = sortInfo

    if (field === key) {
      if (direction === 'asc') {
        setSortInfo({ field: key, direction: 'desc' })
        setItems(sortItems(key, items).reverse())
      } else {
        setSortInfo({ field: key, direction: 'asc' })
        setItems(sortItems(key, items))
      }
    } else {
      setSortInfo({ field: key, direction: 'asc' })
      setItems(sortBy(items, (obj) => get(obj, key)))
    }
  }

  const SortableTableHead = (props: { name: string, sortKey: string }): JSX.Element => (
    <TableCell align='left' className={style['table-cell']}>
      <TableSortLabel
        active={props.sortKey === sortInfo.field}
        direction={sortInfo.direction}
        onClick={() => sortRentalOrders(props.sortKey)}
      >
        {props.name}
      </TableSortLabel>
    </TableCell>
  )

  const openArtObject = (id: string): void => {
    navigate('/verhuurorder/' + props.rentalOrderId + '?artObject=' + id)
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Afbeelding</TableCell>
              <SortableTableHead name='Artikelnummer' sortKey='placement.artObject.articleNumber' />
              <SortableTableHead name='Kunstenaar' sortKey='placement.artObject.displayable.mainArtist.artist' />
              <SortableTableHead name='Titel' sortKey='placement.artObject.displayable.title' />
              <SortableTableHead name='Eenh.' sortKey='placement.numberOfUnits' />
              <SortableTableHead name='Geplaatst' sortKey='placement.actionDate' />
              <SortableTableHead name='Geretouneerd' sortKey='placement.returnPlacement.actionDate' />
              <SortableTableHead name='Laatste ruimte' sortKey='lastCustomerArtLocation.building' />
              <SortableTableHead name='Laatste actie' sortKey='lastAction.type' />
              <SortableTableHead name='Datum laatste actie' sortKey='lastAction.actionDate' />
              <TableCell align='left'>Afmetingen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row' className={style['table-cell']}>
                  <Box display='flex' justifyContent='center' alignItems='center' onClick={() => openArtObject(row.id)}>
                    <ArtImage src={row.placement.artObject.displayable.mainImagePath} alt={row.placement.artObject.displayable.title} size='small' />
                  </Box>
                </TableCell>
                <TableCell align='left' className={style['table-cell']}>
                  {row.placement.artObject.articleNumber}
                </TableCell>
                <TableCell align='left' className={style['table-cell']}>{row.placement.artObject.displayable.mainArtist.artist}</TableCell>
                <TableCell align='left' className={style['table-cell']}>{row.placement.artObject.displayable.title}</TableCell>
                <TableCell align='left' className={style['table-cell']}>{formatDecimal(row.placement.numberOfUnits)}</TableCell>
                <TableCell align='left' className={style['table-cell']}>{flipDateFormat(row.placement.actionDate)}</TableCell>
                <TableCell align='left' className={style['table-cell']}>{hasValue(row.returnPlacement) ? flipDateFormat(row.returnPlacement.actionDate) : '-'}</TableCell>
                <TableCell align='left' className={style['table-cell']}>{artLocationDescription(row)}</TableCell>
                <TableCell align='left' className={style['table-cell']}>{actionChip(row.lastAction.type)}</TableCell>
                <TableCell align='left' className={style['table-cell']}>{flipDateFormat(row.lastAction.actionDate)}</TableCell>
                <TableCell align='left' className={style['table-cell']}>{artSize(row.placement.artObject.displayable)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
