import React from 'react'
import { Box, CircularProgress } from '@mui/material'

export const Loader = (): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center'
      }}
    >
      <CircularProgress sx={{ color: 'var(--color-brand-quarternary-300)' }} size='24px' />
    </Box>
  )
}
