import React from 'react'
import style from './style.module.css'
import { Logo } from '@root/components/atoms/Logo/Component'
import { Avatar, IconButton, Menu, MenuItem, Stack } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import { createUuid } from '@root/misc/helpers'
import { Grid } from '@root/components/atoms/Grid/Component'
import { Link } from 'react-router-dom'
import { logout } from '@root/services/apiService'

export const NavBar = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const logOutAction = async () => {
    await logout()
  }

  return (
    <nav className={style.nav}>
      <Grid>
        <div className={style.wrapper}>
          <Link to='/'><Logo isWhite /></Link>
          <IconButton
            onClick={handleClick}
            size='small'
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar
              sx={{ width: 24, height: 24, backgroundColor: 'transparent', padding: 0 }}
            >
              <AccountCircleIcon sx={{ color: 'var(--color-neutral-100)' }} />
            </Avatar>
          </IconButton>

          <Menu
            id={createUuid()}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={logOutAction}
              sx={{ color: 'var(--color-signal-error-700)' }}
            >
              <Stack gap='24px' direction='row'><LogoutIcon /> Uitloggen</Stack>
            </MenuItem>
          </Menu>
        </div>
      </Grid>

    </nav>
  )
}
