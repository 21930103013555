import { RentalOrder } from '@root/generated/graphql-request'
import style from './style.module.css'
import { Typography } from '@mui/material'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import { Painting } from '@root/components/atoms/Icons/Component'
import Toll from '@mui/icons-material/Toll'
import { Link } from 'react-router-dom'
import { formatDecimal, hasValue } from '@root/misc/helpers'
import { ArtImage, missingImageText } from '@root/components/atoms/ArtImage/Component'

interface Props {
  rentalOrder: RentalOrder
  amountImages: number
}

const ArtObjectInfoAtIndex = (rentalOrder: RentalOrder, index: number): { title: string, mainImagePath: string | undefined | null } => {
  if (hasValue(rentalOrder.showcaseRentalOrderLines)) {
    const rentalOrderLine = rentalOrder.showcaseRentalOrderLines[index]
    if (hasValue(rentalOrderLine)) {
      return {
        title: rentalOrderLine.placement.artObject.displayable.title,
        mainImagePath: rentalOrderLine.placement.artObject.displayable.mainImagePath
      }
    } else {
      return { title: missingImageText(), mainImagePath: '' }
    }
  } else {
    return { title: missingImageText(), mainImagePath: '' }
  }
}

export const RentalOrderCard = (props: Props): JSX.Element => {
  return (
    <div>
      <Link to={`/verhuurorder/${props.rentalOrder.id}`} className={style.link}>
        <div className={style.collage}>
          <div>
            <ArtImage src={ArtObjectInfoAtIndex(props.rentalOrder, 0).mainImagePath} alt={ArtObjectInfoAtIndex(props.rentalOrder, 0).title} size='medium' />
          </div>
          <div>
            <ArtImage src={ArtObjectInfoAtIndex(props.rentalOrder, 1).mainImagePath} alt={ArtObjectInfoAtIndex(props.rentalOrder, 1).title} size='medium' />
          </div>
          <div>
            {
              props.amountImages > 3 && (
                <div className={style.overlay}>
                  <Typography variant='body1' sx={{ color: 'var(--color-neutral-100)' }}>+{props.amountImages - 3}</Typography>
                </div>
              )
            }
            <ArtImage src={ArtObjectInfoAtIndex(props.rentalOrder, 2).mainImagePath} alt={ArtObjectInfoAtIndex(props.rentalOrder, 2).title} size='medium' />
          </div>
        </div>
      </Link>
      <Typography mt='8px' variant='body2' sx={{ color: 'var(--color-neutral-600)' }}>{props.rentalOrder.rentalOrderNumber}</Typography>
      <Typography mt='4px' variant='h4'>{props.rentalOrder.customerSite.name}</Typography>

      <div className={style.metas}>
        <div className={style.meta}>
          <div className={style.icon}>
            <LocationOnOutlinedIcon sx={{ fontSize: 16 }} />
          </div>
          <Typography variant='body2' sx={{ color: 'var(--color-neutral-600)' }}>
            {props.rentalOrder.customerSite.addressLine1} {props.rentalOrder.customerSite.addressLine2}
            <br />
            {props.rentalOrder.customerSite.postalCode}, {props.rentalOrder.customerSite.city}
          </Typography>
        </div>

        <div className={style.meta}>
          <div className={style.icon}>
            <Painting height='16px' width='16px' />
          </div>
          <Typography variant='body2' sx={{ color: 'var(--color-neutral-600)' }}>{formatDecimal(props.rentalOrder.numberOfActiveArtObjects)}</Typography>
        </div>

        <div className={style.meta}>
          <div className={style.icon}>
            <Toll sx={{ fontSize: 16 }} />
          </div>
          <Typography variant='body2' sx={{ color: 'var(--color-neutral-600)' }}>{formatDecimal(props.rentalOrder.numberOfActiveUnits)}</Typography>
        </div>
      </div>
    </div>
  )
}
