import React from 'react'
// import { AccountStatus, Scalars } from '@generated/graphql-request'

type StateSetter<T> = React.Dispatch<React.SetStateAction<T>>

// interface Account {
//   id: Scalars['BigIntID']
//   email: Scalars['String']
//   status: AccountStatus
// }

interface IContext {
  loggedIn: boolean
  setLoggedin: StateSetter<boolean>
  userData: any | null
  setUserData: StateSetter<any | null>
}

export function useAppValue (): IContext {
  const [loggedIn, setLoggedin] = React.useState<boolean>(false)
  const [userData, setUserData] = React.useState<any | null>(null)

  return {
    userData,
    setUserData,
    loggedIn,
    setLoggedin
  }
}

export const appContext = React.createContext<IContext | null>(null)

export function useAppContext (): IContext {
  const c = React.useContext(appContext)
  if (c !== null) {
    return c
  }
  throw Error('No context available')
}
