import React from 'react'
import style from './style.module.css'

export const Grid = (props: React.PropsWithChildren<{ style?: React.CSSProperties }>): JSX.Element => {
  return (
    <div className={style.grid} {...props}>
      {props.children}
    </div>
  )
}
