import { hasValue } from '@root/misc/helpers'
import React from 'react'

interface Props {
  isWhite?: boolean
}

export const Logo = (props: Props): JSX.Element => {
  if (hasValue(props.isWhite) && props.isWhite) {
    return (
      <svg width='80' height='40' viewBox='0 0 80 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_4844_43978)'>
          <path d='M37.8718 7.50087V9.65737H38.7531C39.4456 9.65737 39.836 9.21101 39.836 8.5665C39.836 7.92199 39.4456 7.50045 38.7531 7.50045H37.8718V7.50087ZM37.8718 4.06755V6.0381H38.6778C39.295 6.0381 39.6475 5.67883 39.6475 5.04652C39.6475 4.41421 39.3076 4.06755 38.6778 4.06755H37.8718ZM39.0051 11.1572H36.1465V2.58038H38.8289C40.5415 2.58038 41.4359 3.43566 41.4359 4.89801C41.4359 5.65401 41.0708 6.33596 40.5167 6.63339C41.247 6.98047 41.6374 7.61278 41.6374 8.64139C41.6374 10.2156 40.5798 11.1576 39.0056 11.1576' fill='white' />
          <path d='M44.1191 8.69061C44.1191 9.33512 44.3837 9.74404 44.9882 9.74404C45.5928 9.74404 45.9201 9.3103 45.9201 8.67799V4.5257H47.6075V11.1567H45.9327V10.6612C45.7564 10.8841 45.3155 11.2312 44.5221 11.2312C43.0236 11.2312 42.4316 10.2396 42.4316 8.88876V4.5257H44.1191V8.69019V8.69061Z' fill='white' />
          <path d='M50.7183 6.90601C51.0203 6.99268 51.2976 7.04232 51.6375 7.14161C52.7204 7.46386 53.2496 7.98426 53.2496 9.18662C53.2496 10.5375 52.1163 11.2443 50.794 11.2443C48.9299 11.2443 48.3506 10.166 48.3884 9.01329H50.0006C50.0006 9.48448 50.1642 9.90559 50.8192 9.90559C51.2601 9.90559 51.6127 9.69482 51.6127 9.31073C51.6127 8.92663 51.4112 8.75288 50.9454 8.62919C50.5803 8.52991 50.2904 8.46807 49.9253 8.33176C48.9934 7.99731 48.5273 7.3772 48.5273 6.37341C48.5273 5.25814 49.4844 4.43988 50.8319 4.43988C52.2551 4.43988 53.0986 5.14623 53.1112 6.53454H51.5622C51.5496 6.02633 51.3102 5.7289 50.794 5.7289C50.391 5.7289 50.1263 5.95187 50.1263 6.28675C50.1263 6.62162 50.3152 6.79495 50.7183 6.90644' fill='white' />
          <path d='M55.7065 11.1571H54.0191V4.52608H55.7065V11.1571ZM53.8555 2.77429C53.8555 2.21645 54.3086 1.77051 54.863 1.77051C55.4175 1.77051 55.8706 2.21687 55.8706 2.77429C55.8706 3.33172 55.4297 3.75326 54.863 3.75326C54.2964 3.75326 53.8555 3.31952 53.8555 2.77429Z' fill='white' />
          <path d='M60.2778 6.98048C60.2778 6.33597 59.988 5.92706 59.3712 5.92706C58.7545 5.92706 58.4394 6.3486 58.4394 6.98048V11.1576H56.752V4.52613H58.4394V5.02213C58.603 4.82398 59.0313 4.45209 59.8626 4.45209C61.4369 4.45209 61.9657 5.50552 61.9657 6.78234V11.1576H60.2782V6.98048H60.2778Z' fill='white' />
          <path d='M64.5461 7.12896H67.5936C67.5179 6.38517 66.9512 5.81512 66.032 5.81512C65.1754 5.81512 64.634 6.37297 64.5461 7.12896ZM64.5082 8.38096C64.5965 9.26106 65.2259 9.79409 66.183 9.79409C67.0395 9.79409 67.5305 9.34773 67.883 8.8025L69.13 9.5463C68.7269 10.3768 67.8203 11.2691 66.183 11.2691C64.1426 11.2691 62.7324 9.96784 62.7324 7.86056C62.7324 5.87739 64.0925 4.41504 66.0446 4.41504C67.9966 4.41504 69.3441 5.71626 69.3441 7.69943C69.3441 7.86056 69.3441 8.09615 69.3189 8.38096H64.5082Z' fill='white' />
          <path d='M72.103 6.90601C72.4051 6.99268 72.6823 7.04232 73.0223 7.14161C74.1051 7.46386 74.6344 7.98426 74.6344 9.18662C74.6344 10.5375 73.501 11.2443 72.1788 11.2443C70.3146 11.2443 69.7353 10.166 69.7732 9.01329H71.3853C71.3853 9.48448 71.549 9.90559 72.204 9.90559C72.6449 9.90559 72.9974 9.69482 72.9974 9.31073C72.9974 8.92663 72.7959 8.75288 72.3302 8.62919C71.965 8.52991 71.6752 8.46807 71.31 8.33176C70.3782 7.99731 69.912 7.3772 69.912 6.37341C69.912 5.25814 70.8691 4.43988 72.2166 4.43988C73.6398 4.43988 74.4833 5.14623 74.496 6.53454H72.947C72.9343 6.02633 72.695 5.7289 72.1788 5.7289C71.7757 5.7289 71.5111 5.95187 71.5111 6.28675C71.5111 6.62162 71.7 6.79495 72.103 6.90644' fill='white' />
          <path d='M77.4683 6.90601C77.7703 6.99268 78.0476 7.04232 78.3875 7.14161C79.4704 7.46386 79.9996 7.98426 79.9996 9.18662C79.9996 10.5375 78.8662 11.2443 77.544 11.2443C75.6799 11.2443 75.1006 10.166 75.1384 9.01329H76.7506C76.7506 9.48448 76.9142 9.90559 77.5692 9.90559C78.0101 9.90559 78.3627 9.69482 78.3627 9.31073C78.3627 8.92663 78.1612 8.75288 77.695 8.62919C77.3299 8.52991 77.04 8.46807 76.6748 8.33176C75.743 7.99731 75.2768 7.3772 75.2768 6.37341C75.2768 5.25814 76.2339 4.43988 77.5814 4.43988C79.0047 4.43988 79.8482 5.14623 79.8608 6.53454H78.3118C78.2991 6.02633 78.0598 5.7289 77.5436 5.7289C77.1405 5.7289 76.8759 5.95187 76.8759 6.28675C76.8759 6.62162 77.0648 6.79495 77.4678 6.90644' fill='white' />
          <path d='M38.6623 18.2956H40.5639L39.6068 15.7175L38.6623 18.2956ZM41.1057 19.7583H38.1209L37.2643 22.1134H35.5391L38.6876 13.5366H40.526L43.8256 22.1134H41.9741L41.1053 19.7583H41.1057Z' fill='white' />
          <path d='M47.3779 17.1925H46.9496C46.2946 17.1925 46.0683 17.5025 46.0683 18.0726V22.113H44.3809V15.4819H46.0683V16.0149C46.3077 15.6805 46.6854 15.4571 47.3779 15.4571V17.1925Z' fill='white' />
          <path d='M50.1727 15.4823H51.2055V16.8828H50.1727V19.9691C50.1727 20.4647 50.3363 20.6754 50.8151 20.6754H51.2055V22.0885C51.1676 22.0885 50.8656 22.1752 50.2863 22.1752C49.0141 22.1752 48.4853 21.5433 48.4853 20.4398V16.8824H47.8555V15.4819H48.4853V14.4407H50.1727V15.4819V15.4823Z' fill='white' />
          <path d='M38.5546 27.7777C38.9703 27.951 39.3859 28.0999 39.8643 28.3107C40.9097 28.7693 41.5391 29.4882 41.5391 30.7028C41.5391 32.3389 40.3552 33.3675 38.6678 33.3675C36.3632 33.3675 35.8091 31.8925 35.8718 30.5665H37.5344C37.5218 31.2606 37.8112 31.8803 38.6678 31.8803C39.285 31.8803 39.738 31.5332 39.738 30.8887C39.738 30.3187 39.3729 30.0213 38.8062 29.7857C38.3148 29.5627 37.7986 29.4138 37.295 29.129C36.4511 28.6952 35.9976 27.9144 35.9976 26.8858C35.9976 25.4853 37.0304 24.4689 38.6674 24.4689C40.632 24.4689 41.3245 25.6338 41.2618 27.0347H39.6497C39.6497 26.4024 39.3098 25.9438 38.6674 25.9438C38.0628 25.9438 37.7355 26.3157 37.7355 26.7991C37.7355 27.2455 37.9875 27.5429 38.5542 27.7785' fill='white' />
          <path d='M43.9582 29.1782H47.0057C46.93 28.4344 46.3633 27.8643 45.4441 27.8643C44.5875 27.8643 44.0461 28.4222 43.9582 29.1782ZM43.9203 30.4302C44.0086 31.3103 44.638 31.8433 45.5951 31.8433C46.4516 31.8433 46.9426 31.3969 47.2951 30.8517L48.5421 31.5955C48.1391 32.426 47.2325 33.3183 45.5951 33.3183C43.5547 33.3183 42.1445 32.017 42.1445 29.9098C42.1445 27.9266 43.5046 26.4642 45.4567 26.4642C47.4087 26.4642 48.7562 27.7655 48.7562 29.7486C48.7562 29.9098 48.7562 30.1453 48.731 30.4302H43.9203Z' fill='white' />
          <path d='M52.5088 28.2859H52.0805C51.4255 28.2859 51.1991 28.5959 51.1991 29.166V33.2063H49.5117V26.5753H51.1991V27.1083C51.4385 26.7739 51.8163 26.5505 52.5088 26.5505V28.2859Z' fill='white' />
          <path d='M56.2746 31.1239L57.7984 26.5753H59.5489L57.1686 33.2064H55.3175L52.8867 26.5753H54.7508L56.2746 31.1239Z' fill='white' />
          <path d='M61.7792 33.2064H60.0914V26.5753H61.7792V33.2064ZM59.9277 24.8235C59.9277 24.2657 60.3812 23.8198 60.9353 23.8198C61.4894 23.8198 61.9429 24.2661 61.9429 24.8235C61.9429 25.381 61.502 25.8025 60.9353 25.8025C60.3686 25.8025 59.9277 25.3688 59.9277 24.8235Z' fill='white' />
          <path d='M67.4839 31.2729L68.4158 32.3881C68.0127 32.8097 67.2193 33.3179 66.0607 33.3179C63.9324 33.3179 62.5723 31.8307 62.5723 29.9094C62.5723 27.9881 64.0334 26.4639 66.0481 26.4639C67.2698 26.4639 68.0127 26.9473 68.4158 27.3562L67.4461 28.5089C67.1688 28.2485 66.7283 27.9637 66.0607 27.9637C64.9526 27.9637 64.2597 28.7941 64.2597 29.8972C64.2597 31.0869 65.1036 31.8185 66.0733 31.8185C66.7532 31.8185 67.1814 31.5211 67.4839 31.2733' fill='white' />
          <path d='M70.2171 29.1782H73.265C73.1893 28.4344 72.6226 27.8643 71.7034 27.8643C70.8469 27.8643 70.3054 28.4222 70.2175 29.1782M70.1796 30.4302C70.2676 31.3103 70.8974 31.8433 71.8549 31.8433C72.711 31.8433 73.2024 31.3969 73.5549 30.8517L74.8019 31.5955C74.3988 32.426 73.4922 33.3183 71.8549 33.3183C69.8145 33.3183 68.4043 32.017 68.4043 29.9098C68.4043 27.9266 69.7644 26.4642 71.7165 26.4642C73.6685 26.4642 75.016 27.7655 75.016 29.7486C75.016 29.9098 75.016 30.1453 74.9908 30.4302H70.1801H70.1796Z' fill='white' />
          <path d='M37.3963 36.089C38.1519 36.089 38.631 36.5274 38.719 37.1584H37.8957C37.8486 36.9325 37.6664 36.7932 37.3963 36.7932C37.0118 36.7932 36.7753 37.1386 36.7753 37.6435C36.7753 38.1483 37.0114 38.487 37.3963 38.487C37.6664 38.487 37.8486 38.3473 37.8957 38.1218H38.719C38.6315 38.7596 38.1523 39.1912 37.3963 39.1912C36.5393 39.1912 35.9453 38.5602 35.9453 37.6439C35.9453 36.7276 36.5393 36.0898 37.3963 36.0898V36.089Z' fill='white' />
          <path d='M40.5287 39.1904C39.6515 39.1904 39.0508 38.5526 39.0508 37.643C39.0508 36.7335 39.6515 36.089 40.5287 36.089C41.4058 36.089 42.0066 36.7268 42.0066 37.643C42.0066 38.5593 41.4058 39.1904 40.5287 39.1904ZM40.5287 36.7861C40.1101 36.7861 39.8808 37.1445 39.8808 37.6426C39.8808 38.1407 40.1101 38.4929 40.5287 38.4929C40.9473 38.4929 41.1766 38.1344 41.1766 37.6426C41.1766 37.1508 40.9473 36.7861 40.5287 36.7861Z' fill='white' />
          <path d='M42.5391 36.155H43.3758V36.4272C43.5378 36.2413 43.8348 36.0886 44.1857 36.0886C44.8672 36.0886 45.2517 36.5269 45.2517 37.1845V39.1239H44.415V37.3574C44.415 37.0452 44.253 36.8328 43.9223 36.8328C43.6657 36.8328 43.4431 36.9855 43.3758 37.2446V39.1243H42.5391V36.1555V36.155Z' fill='white' />
          <path d='M45.9473 36.155H46.784V36.4272C46.946 36.2413 47.243 36.0886 47.5939 36.0886C48.2754 36.0886 48.6599 36.5269 48.6599 37.1845V39.1239H47.8232V37.3574C47.8232 37.0452 47.6612 36.8328 47.3305 36.8328C47.0739 36.8328 46.8513 36.9855 46.784 37.2446V39.1243H45.9473V36.1555V36.155Z' fill='white' />
          <path d='M51.9666 38.1542C51.8585 38.7852 51.3865 39.1904 50.6305 39.1904C49.7533 39.1904 49.166 38.5593 49.166 37.643C49.166 36.7268 49.7735 36.089 50.617 36.089C51.4605 36.089 51.9868 36.6935 51.9868 37.5635V37.816H49.9759C50.0028 38.2611 50.2594 38.5265 50.6305 38.5265C50.914 38.5265 51.1231 38.407 51.1904 38.1546H51.9666V38.1542ZM49.9961 37.3174H51.1635C51.1568 37.0053 50.9679 36.7596 50.6237 36.7596C50.2998 36.7596 50.0705 36.9523 49.9961 37.3174Z' fill='white' />
          <path d='M53.8084 36.089C54.564 36.089 55.0432 36.5274 55.1311 37.1584H54.3078C54.2607 36.9325 54.0785 36.7932 53.8084 36.7932C53.4239 36.7932 53.1875 37.1386 53.1875 37.6435C53.1875 38.1483 53.4235 38.487 53.8084 38.487C54.0785 38.487 54.2607 38.3473 54.3078 38.1218H55.1311C55.0436 38.7596 54.5644 39.1912 53.8084 39.1912C52.9514 39.1912 52.3574 38.5602 52.3574 37.6439C52.3574 36.7276 52.9514 36.0898 53.8084 36.0898V36.089Z' fill='white' />
          <path d='M55.8535 36.8193H55.3945V36.155H55.8535V35.3515H56.6836V36.155H57.3449V36.8193H56.6836V38.121C56.6836 38.3536 56.8119 38.4596 57.0277 38.4596C57.156 38.4596 57.2978 38.4197 57.392 38.3599V39.0907C57.2772 39.1504 57.0748 39.1836 56.8523 39.1836C56.238 39.1836 55.8535 38.8849 55.8535 38.2274V36.8193Z' fill='white' />
          <path d='M62.162 38.0482L62.7018 36.1555H63.5116L62.5941 39.1243H61.8246L61.332 37.3044L60.8393 39.1243H60.0699L59.1523 36.1555H59.9891L60.5289 38.0482L61.0215 36.1555H61.6626L62.162 38.0482Z' fill='white' />
          <path d='M64.3822 35.7701C64.1121 35.7701 63.8828 35.5774 63.8828 35.2985C63.8828 35.0196 64.1121 34.8269 64.3822 34.8269C64.6523 34.8269 64.8748 35.0263 64.8748 35.2985C64.8748 35.5707 64.6455 35.7701 64.3822 35.7701ZM64.8004 39.1239H63.9636V36.155H64.8004V39.1239Z' fill='white' />
          <path d='M65.7109 36.8193H65.252V36.155H65.7109V35.3515H66.541V36.155H67.2023V36.8193H66.541V38.121C66.541 38.3536 66.6693 38.4596 66.8851 38.4596C67.0134 38.4596 67.1552 38.4197 67.2494 38.3599V39.0907C67.1346 39.1504 66.9322 39.1836 66.7097 39.1836C66.0954 39.1836 65.7109 38.8849 65.7109 38.2274V36.8193Z' fill='white' />
          <path d='M67.8574 34.8071H68.6942V36.4277C68.8562 36.2417 69.1532 36.089 69.504 36.089C70.1856 36.089 70.5701 36.5274 70.5701 37.1849V39.1243H69.7333V37.3578C69.7333 37.0457 69.5713 36.8332 69.2407 36.8332C68.9841 36.8332 68.7615 36.9859 68.6942 37.2451V39.1248H67.8574V34.808V34.8071Z' fill='white' />
          <path d='M74.3694 39.1239V38.9645C74.268 39.0642 73.9647 39.1769 73.634 39.1769C73.0265 39.1769 72.5137 38.8315 72.5137 38.2139C72.5137 37.6494 73.0265 37.2644 73.6811 37.2644C73.9445 37.2644 74.2478 37.3507 74.3694 37.4369V37.2177C74.3694 36.9653 74.2141 36.7596 73.8767 36.7596C73.6407 36.7596 73.4989 36.866 73.4312 37.0385H72.6348C72.7223 36.5004 73.215 36.0886 73.9037 36.0886C74.7068 36.0886 75.1725 36.5202 75.1725 37.2308V39.1235H74.3694V39.1239ZM74.3694 38.1012C74.2953 37.9417 74.0656 37.842 73.8229 37.842C73.5599 37.842 73.2899 37.9548 73.2899 38.2072C73.2899 38.4596 73.5599 38.5724 73.8229 38.5724C74.0656 38.5724 74.2953 38.4727 74.3694 38.3132V38.1008V38.1012Z' fill='white' />
          <path d='M77.6819 36.9653C77.567 36.8988 77.4189 36.8656 77.2637 36.8656C76.9801 36.8656 76.7509 37.0116 76.6903 37.2842V39.1239H75.8535V36.155H76.6903V36.4474C76.8186 36.235 77.0681 36.089 77.3651 36.089C77.5069 36.089 77.6352 36.1155 77.6823 36.142V36.9657L77.6819 36.9653Z' fill='white' />
          <path d='M78.4102 36.8193H77.9512V36.155H78.4102V35.3515H79.2402V36.155H79.9015V36.8193H79.2402V38.121C79.2402 38.3536 79.3685 38.4596 79.5843 38.4596C79.7126 38.4596 79.8544 38.4197 79.9486 38.3599V39.0907C79.8338 39.1504 79.6314 39.1836 79.4089 39.1836C78.7947 39.1836 78.4102 38.8849 78.4102 38.2274V36.8193Z' fill='white' />
          <path d='M6.23683 27.8429H6.23305C6.22758 27.8055 6.22295 27.7668 6.21748 27.7285C6.17667 27.4862 6.14765 27.2569 6.12661 27.0385C5.46948 21.7243 5.75135 15.7773 6.6243 11.4672H6.63986C7.13587 9.38983 8.29068 7.07304 12.0639 6.64814C13.4379 6.49332 14.6613 6.45588 15.7543 6.49626C18.7787 6.556 21.6672 7.3019 23.6992 8.5539C25.597 9.72344 26.5486 11.1605 26.5275 12.8257L32.2768 12.8955C32.3227 9.22744 30.3601 5.98217 26.7501 3.75794C23.7724 1.92327 19.8393 0.884568 15.6752 0.834084C13.382 0.806318 11.4051 0.951879 9.70423 1.27413C9.66048 1.28465 9.61883 1.29517 9.57381 1.30527C2.96254 2.78444 1.34916 8.52992 0.790474 11.3823C0.352527 13.8703 0.0912729 16.6839 0.0332166 19.641C-0.0143224 22.076 0.0832797 24.4681 0.313822 26.7188C0.557407 28.9536 0.907848 31.2746 1.793 33.0566L6.23683 27.8433V27.8429Z' fill='white' />
          <path d='M1.78798 33.094L1.77367 33.0654C0.884739 31.2758 0.5364 28.9586 0.292395 26.7209C0.0589075 24.4432 -0.0353289 22.0612 0.0117893 19.6405C0.069425 16.686 0.324368 13.9064 0.769046 11.3789C1.20615 9.1479 1.90115 7.3288 2.89441 5.81765C4.44805 3.4529 6.69374 1.92829 9.56879 1.28462C9.59782 1.27831 9.62517 1.27158 9.65293 1.26485L9.69879 1.25391C11.4123 0.929133 13.3669 0.784834 15.6752 0.813021C19.8431 0.863504 23.78 1.90305 26.7615 3.74024C30.3778 5.96868 32.3441 9.22026 32.2983 12.8963V12.9174L26.5065 12.8471V12.8261C26.5276 11.1689 25.5793 9.73772 23.6883 8.57238C21.6656 7.32627 18.7737 6.57701 15.7539 6.51769C14.5848 6.47436 13.3441 6.52526 12.0665 6.66914C8.14305 7.11088 7.10224 9.62202 6.66051 11.4727L6.65672 11.4891H6.642C5.71688 16.0671 5.52757 22.0221 6.14768 27.036C6.16955 27.2644 6.20026 27.4962 6.23855 27.7251C6.24107 27.7432 6.24359 27.7613 6.2457 27.7789C6.24738 27.7924 6.24948 27.8063 6.25117 27.8198L6.28566 27.8147L6.25327 27.8568L1.78882 33.0945L1.78798 33.094ZM15.1195 0.851725C13.0534 0.851725 11.2785 0.997286 9.70805 1.29472L9.66345 1.30566C9.63568 1.31239 9.6075 1.31912 9.57847 1.32585C6.71436 1.967 4.4775 3.48572 2.92975 5.84037C1.93985 7.34689 1.24654 9.16094 0.810696 11.3864C0.366438 13.9115 0.111495 16.6889 0.0538592 19.6414C0.00674093 22.0604 0.100977 24.4407 0.334465 26.7167C0.576787 28.9384 0.92176 31.2388 1.79723 33.0183L6.21078 27.8408C6.20826 27.8223 6.20573 27.8033 6.20321 27.7848C6.20068 27.7672 6.19858 27.7495 6.19606 27.7318C6.15777 27.5025 6.12706 27.2699 6.10518 27.041C5.48381 22.0175 5.67481 16.0482 6.60329 11.4638L6.60666 11.4466H6.62264C7.06943 9.58584 8.12285 7.07133 12.061 6.62791C13.3408 6.48361 14.5835 6.43271 15.7543 6.47604C18.7812 6.53578 21.6807 7.28673 23.7097 8.53704C25.6062 9.70574 26.5612 11.1416 26.5486 12.8059L32.2558 12.8753C32.2941 9.2232 30.3353 5.99308 26.7388 3.77684C23.7644 1.94302 19.8347 0.905574 15.6744 0.85509C15.4872 0.852987 15.3017 0.851725 15.1191 0.851725H15.1195Z' fill='white' />
          <path d='M23.9694 21.4752C23.001 20.6498 21.8664 19.9935 20.6467 19.535C20.6345 19.5383 20.6219 19.5409 20.6097 19.5438C19.738 19.882 18.1364 20.5573 16.3098 20.5413C14.3767 20.5244 13.9 19.8871 13.9059 19.4319C13.9059 19.4231 13.9067 19.4146 13.9072 19.4058L8.04347 19.2716C8.0178 21.3162 8.98583 23.0667 10.7696 24.2001C12.2164 25.1193 14.1747 25.6006 16.5908 25.63C20.1242 25.673 23.2551 24.9161 25.7725 23.5329C25.3169 22.8055 24.7077 22.1037 23.9698 21.4748' fill='url(#paint0_linear_4844_43978)' />
          <path d='M16.8705 25.6527C16.7771 25.6527 16.6842 25.6523 16.5903 25.651C14.1701 25.6216 12.2079 25.1395 10.7578 24.2181C8.96813 23.0806 7.99632 21.3237 8.02198 19.2716V19.2501L13.9286 19.3852L13.9273 19.4163C13.9273 19.4218 13.9269 19.4268 13.9265 19.4323C13.9248 19.5602 13.9622 19.7533 14.1507 19.9455C14.401 20.2013 14.9791 20.5089 16.3097 20.5202C18.0203 20.5354 19.5306 19.9434 20.433 19.59C20.4919 19.5669 20.5483 19.545 20.6021 19.524L20.6211 19.5194C20.6278 19.5177 20.6345 19.516 20.6412 19.5143L20.6476 19.5126L20.6539 19.5152C21.8979 19.983 23.0182 20.6367 23.9829 21.4588C24.7157 22.0831 25.3236 22.7773 25.7898 23.5219L25.8015 23.5408L25.7822 23.5513C23.2761 24.9283 20.1999 25.6527 16.8701 25.6527H16.8705ZM8.06405 19.293C8.04554 21.3212 9.00936 23.057 10.7805 24.1824C12.2239 25.0995 14.1785 25.5795 16.5908 25.609C20.0131 25.6506 23.1764 24.93 25.7426 23.5253C25.2803 22.7916 24.6791 22.1071 23.9559 21.4908C22.9963 20.6733 21.8827 20.0225 20.6459 19.5568C20.6408 19.5581 20.6362 19.5593 20.6312 19.5602L20.6152 19.564C20.5643 19.5837 20.5079 19.606 20.449 19.6292C19.5436 19.9838 18.0283 20.5774 16.3097 20.5623C14.9648 20.5505 14.3771 20.2362 14.1208 19.975C13.9227 19.7726 13.8831 19.5677 13.8848 19.4319C13.8848 19.4302 13.8848 19.4281 13.8848 19.4264L8.06405 19.2935V19.293Z' fill='url(#paint1_linear_4844_43978)' />
          <path d='M13.9042 19.4319C13.9113 18.8496 14.4679 18.373 16.0943 18.4609C17.4839 18.5358 19.1267 19.023 20.0043 19.3145C21.4667 19.7705 22.8318 20.5063 23.9681 21.4748C25.7073 22.9565 26.7325 24.8412 26.7115 26.516C26.6833 28.7638 25.7822 30.4306 23.9559 31.6115C22.1263 32.7949 19.4301 33.426 16.3644 33.389C16.0855 33.3856 15.815 33.3801 15.5521 33.3734C14.9063 33.3734 13.0548 33.3368 10.7338 32.9678C7.16544 32.4007 6.3741 28.6229 6.23527 27.8425L1.79102 33.0562C1.91765 33.3111 2.05479 33.556 2.20456 33.7869C3.64587 36.0095 6.28744 37.792 9.04133 38.4193C11.0401 38.8744 13.4852 39.0133 16.2938 39.0474C20.5542 39.0991 24.2959 38.163 27.1137 36.3406C30.5596 34.1117 32.4086 30.739 32.4608 26.5858C32.5033 23.2161 30.7792 19.7937 27.7313 17.1967C24.706 14.6191 20.8045 13.1723 16.7456 13.1227C11.1431 13.0545 8.07962 16.24 8.04133 19.2716' fill='white' />
          <path d='M16.6296 39.0705C16.5185 39.0705 16.407 39.0697 16.2951 39.0684C12.9657 39.028 10.7958 38.84 9.03851 38.4399C6.2598 37.8072 3.63506 36.0289 2.1887 33.7987C2.04482 33.5766 1.90515 33.3301 1.77389 33.0659L1.76758 33.0533L1.77641 33.0428L6.24928 27.7954L6.25727 27.8391C6.44574 28.9001 7.32542 32.405 10.7381 32.9473C13.0381 33.3128 14.8698 33.3524 15.5518 33.3524H15.553C15.8248 33.3595 16.0982 33.3646 16.3658 33.368C19.4281 33.4054 22.1193 32.7752 23.9455 31.5939C25.7651 30.4172 26.6632 28.7558 26.6914 26.5156C26.7125 24.8467 25.6897 22.9683 23.9552 21.4904C22.8387 20.5388 21.4706 19.7933 19.999 19.3343C19.1378 19.0478 17.4857 18.5564 16.094 18.4815C15.1513 18.4306 14.5042 18.5632 14.1702 18.8757C14.0112 19.0247 13.9287 19.2119 13.9262 19.4319H13.8841C13.8871 19.1993 13.9737 19.0024 14.1416 18.845C14.4844 18.5245 15.142 18.3877 16.0966 18.4395C17.4933 18.5148 19.1492 19.0074 20.012 19.2943C21.4887 19.755 22.8618 20.5034 23.9826 21.4584C25.7264 22.9443 26.7545 24.8349 26.7335 26.516C26.7053 28.7714 25.8008 30.4437 23.9687 31.6288C22.1357 32.8143 19.4356 33.4466 16.3658 33.4096C16.0978 33.4062 15.8244 33.4012 15.553 33.394H15.5513C14.8685 33.394 13.0347 33.3545 10.7318 32.9885C7.3372 32.4492 6.42976 29.0074 6.22404 27.89L1.81764 33.0592C1.94638 33.317 2.0831 33.5581 2.22362 33.7752C3.66409 35.9965 6.27873 37.768 9.04734 38.3982C10.8017 38.7975 12.9691 38.9851 16.2951 39.0255C20.5522 39.0773 24.2892 38.1425 27.1033 36.3221C30.5429 34.0974 32.389 30.7302 32.4407 26.585C32.4828 23.2215 30.7617 19.8051 27.7188 17.2123C24.6973 14.6376 20.8004 13.1925 16.7465 13.1433C11.1555 13.0756 8.10161 16.2497 8.06375 19.2712H8.02168C8.05996 16.2287 11.1306 13.0331 16.747 13.1012C20.8109 13.1505 24.7171 14.5993 27.7461 17.1803C30.7987 19.7815 32.5253 23.2098 32.4828 26.5859C32.4306 30.7462 30.5783 34.1252 27.126 36.3579C24.3789 38.1349 20.755 39.0701 16.6292 39.0701L16.6296 39.0705Z' fill='white' />
          <path d='M26.5285 12.826C26.5163 13.8 26.2866 14.8185 25.791 15.7718C26.4704 16.1992 27.1204 16.6746 27.7338 17.1971C28.6484 17.9767 29.4431 18.8307 30.1074 19.7352C31.473 17.8101 32.2454 15.4912 32.2782 12.8959L26.5289 12.826H26.5285Z' fill='url(#paint2_linear_4844_43978)' />
          <path d='M30.1059 19.771L30.0886 19.7474C29.418 18.8341 28.6204 17.9813 27.7184 17.2127C27.1147 16.6982 26.4618 16.2194 25.7781 15.7891L25.7617 15.7785L25.7706 15.7613C26.2388 14.8614 26.4929 13.8458 26.5059 12.8252V12.8042L32.2977 12.8745V12.8955C32.2653 15.4529 31.5337 17.7579 30.1227 19.7466L30.1059 19.7701V19.771ZM25.8168 15.7642C26.4963 16.1929 27.145 16.6696 27.7457 17.1811C28.6422 17.9451 29.4361 18.7924 30.1054 19.6994C31.4963 17.7285 32.2194 15.447 32.2552 12.9169L26.548 12.8475C26.532 13.8614 26.2796 14.869 25.8173 15.7642H25.8168Z' fill='url(#paint3_linear_4844_43978)' />
        </g>
        <defs>
          <linearGradient id='paint0_linear_4844_43978' x1='14.1583' y1='26.1643' x2='18.7296' y2='18.2468' gradientUnits='userSpaceOnUse'>
            <stop stopColor='white' />
            <stop offset='0.47' stopColor='white' />
            <stop offset='0.67' stopColor='white' stopOpacity='0.99' />
            <stop offset='0.74' stopColor='white' stopOpacity='0.97' />
            <stop offset='0.79' stopColor='white' stopOpacity='0.92' />
            <stop offset='0.83' stopColor='white' stopOpacity='0.85' />
            <stop offset='0.87' stopColor='white' stopOpacity='0.77' />
            <stop offset='0.9' stopColor='white' stopOpacity='0.66' />
            <stop offset='0.93' stopColor='white' stopOpacity='0.53' />
            <stop offset='0.95' stopColor='white' stopOpacity='0.38' />
            <stop offset='0.97' stopColor='white' stopOpacity='0.21' />
            <stop offset='1' stopColor='white' stopOpacity='0' />
          </linearGradient>
          <linearGradient id='paint1_linear_4844_43978' x1='14.1482' y1='26.1828' x2='18.741' y2='18.2278' gradientUnits='userSpaceOnUse'>
            <stop stopColor='white' />
            <stop offset='0.47' stopColor='white' />
            <stop offset='0.67' stopColor='white' stopOpacity='0.99' />
            <stop offset='0.74' stopColor='white' stopOpacity='0.97' />
            <stop offset='0.79' stopColor='white' stopOpacity='0.92' />
            <stop offset='0.83' stopColor='white' stopOpacity='0.85' />
            <stop offset='0.87' stopColor='white' stopOpacity='0.77' />
            <stop offset='0.9' stopColor='white' stopOpacity='0.66' />
            <stop offset='0.93' stopColor='white' stopOpacity='0.53' />
            <stop offset='0.95' stopColor='white' stopOpacity='0.38' />
            <stop offset='0.97' stopColor='white' stopOpacity='0.21' />
            <stop offset='1' stopColor='white' stopOpacity='0' />
          </linearGradient>
          <linearGradient id='paint2_linear_4844_43978' x1='30.0636' y1='12.0898' x2='27.6114' y2='18.8269' gradientUnits='userSpaceOnUse'>
            <stop stopColor='white' />
            <stop offset='0.2' stopColor='white' stopOpacity='0.99' />
            <stop offset='0.34' stopColor='white' stopOpacity='0.95' />
            <stop offset='0.45' stopColor='white' stopOpacity='0.9' />
            <stop offset='0.56' stopColor='white' stopOpacity='0.81' />
            <stop offset='0.65' stopColor='white' stopOpacity='0.7' />
            <stop offset='0.74' stopColor='white' stopOpacity='0.57' />
            <stop offset='0.83' stopColor='white' stopOpacity='0.41' />
            <stop offset='0.91' stopColor='white' stopOpacity='0.23' />
            <stop offset='0.99' stopColor='white' stopOpacity='0.03' />
            <stop offset='1' stopColor='white' stopOpacity='0' />
          </linearGradient>
          <linearGradient id='paint3_linear_4844_43978' x1='30.0701' y1='12.0642' x2='27.5976' y2='18.858' gradientUnits='userSpaceOnUse'>
            <stop stopColor='white' />
            <stop offset='0.2' stopColor='white' stopOpacity='0.99' />
            <stop offset='0.34' stopColor='white' stopOpacity='0.95' />
            <stop offset='0.45' stopColor='white' stopOpacity='0.9' />
            <stop offset='0.56' stopColor='white' stopOpacity='0.81' />
            <stop offset='0.65' stopColor='white' stopOpacity='0.7' />
            <stop offset='0.74' stopColor='white' stopOpacity='0.57' />
            <stop offset='0.83' stopColor='white' stopOpacity='0.41' />
            <stop offset='0.91' stopColor='white' stopOpacity='0.23' />
            <stop offset='0.99' stopColor='white' stopOpacity='0.03' />
            <stop offset='1' stopColor='white' stopOpacity='0' />
          </linearGradient>
          <clipPath id='clip0_4844_43978'>
            <rect width='80' height='38.3807' fill='white' transform='translate(0 0.809631)' />
          </clipPath>
        </defs>
      </svg>

    )
  }

  return (
    <svg width='120' height='58' viewBox='0 0 120 58' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_4844_6222)'>
        <path d='M56.7245 10.1186V13.3778H58.0465C59.0852 13.3778 59.6708 12.7032 59.6708 11.7291C59.6708 10.755 59.0852 10.1179 58.0465 10.1179H56.7245V10.1186ZM56.7245 4.92972V7.90786H57.9336C58.8593 7.90786 59.3881 7.36488 59.3881 6.40926C59.3881 5.45363 58.8782 4.92972 57.9336 4.92972H56.7245ZM58.4245 15.6444H54.1367V2.68213H58.1601C60.729 2.68213 62.0705 3.97473 62.0705 6.18481C62.0705 7.32737 61.5228 8.35802 60.6918 8.80754C61.7872 9.33208 62.3728 10.2877 62.3728 11.8423C62.3728 14.2215 60.7864 15.6451 58.4251 15.6451' fill='#1A396E' />
        <path d='M66.0935 11.9166C66.0935 12.8907 66.4904 13.5087 67.3972 13.5087C68.304 13.5087 68.7949 12.8532 68.7949 11.8975V5.62207H71.3259V15.6437H68.8139V14.8947C68.5495 15.2317 67.8881 15.7563 66.698 15.7563C64.4503 15.7563 63.5625 14.2577 63.5625 12.2161V5.62207H66.0935V11.916V11.9166Z' fill='#1A396E' />
        <path d='M75.9933 9.21931C76.4463 9.35029 76.8622 9.42532 77.372 9.57537C78.9963 10.0624 79.7901 10.8489 79.7901 12.666C79.7901 14.7076 78.0901 15.7758 76.1068 15.7758C73.3108 15.7758 72.4419 14.1462 72.4987 12.4041H74.9167C74.9167 13.1162 75.1622 13.7527 76.1447 13.7527C76.806 13.7527 77.3348 13.4341 77.3348 12.8536C77.3348 12.2731 77.0326 12.0105 76.334 11.8236C75.7863 11.6735 75.3515 11.5801 74.8038 11.3741C73.4061 10.8686 72.7069 9.93142 72.7069 8.41438C72.7069 6.72884 74.1425 5.49219 76.1636 5.49219C78.2984 5.49219 79.5636 6.55972 79.5825 8.65789H77.2591C77.2402 7.88983 76.8811 7.44031 76.1068 7.44031C75.5023 7.44031 75.1054 7.77729 75.1054 8.2834C75.1054 8.78951 75.3888 9.05146 75.9933 9.21995' fill='#1A396E' />
        <path d='M83.4738 15.6442H80.9427V5.62257H83.4738V15.6442ZM80.6973 2.97505C80.6973 2.13197 81.3769 1.45801 82.2086 1.45801C83.0403 1.45801 83.7199 2.1326 83.7199 2.97505C83.7199 3.8175 83.0585 4.45459 82.2086 4.45459C81.3586 4.45459 80.6973 3.79907 80.6973 2.97505Z' fill='#1A396E' />
        <path d='M90.3316 9.33197C90.3316 8.35791 89.8968 7.7399 88.9717 7.7399C88.0466 7.7399 87.574 8.37698 87.574 9.33197V15.6449H85.043V5.62265H87.574V6.37227C87.8195 6.0728 88.4618 5.51074 89.7087 5.51074C92.07 5.51074 92.8632 7.10281 92.8632 9.0325V15.6449H90.3322V9.33197H90.3316Z' fill='#1A396E' />
        <path d='M96.734 9.55621H101.305C101.192 8.4321 100.342 7.57057 98.9628 7.57057C97.678 7.57057 96.8659 8.41366 96.734 9.55621ZM96.6772 11.4484C96.8097 12.7785 97.7537 13.5841 99.1893 13.5841C100.474 13.5841 101.21 12.9095 101.739 12.0855L103.61 13.2096C103.005 14.4647 101.645 15.8132 99.1893 15.8132C96.1289 15.8132 94.0137 13.8467 94.0137 10.6619C94.0137 7.66467 96.0538 5.45459 98.9817 5.45459C101.91 5.45459 103.931 7.42115 103.931 10.4184C103.931 10.6619 103.931 11.0179 103.893 11.4484H96.6772Z' fill='#1A396E' />
        <path d='M108.069 9.21931C108.523 9.35029 108.938 9.42532 109.448 9.57537C111.072 10.0624 111.866 10.8489 111.866 12.666C111.866 14.7076 110.166 15.7758 108.183 15.7758C105.387 15.7758 104.518 14.1462 104.575 12.4041H106.993C106.993 13.1162 107.238 13.7527 108.221 13.7527C108.882 13.7527 109.411 13.4341 109.411 12.8536C109.411 12.2731 109.109 12.0105 108.41 11.8236C107.862 11.6735 107.428 11.5801 106.88 11.3741C105.482 10.8686 104.783 9.93142 104.783 8.41438C104.783 6.72884 106.219 5.49219 108.24 5.49219C110.375 5.49219 111.64 6.55972 111.659 8.65789H109.335C109.316 7.88983 108.957 7.44031 108.183 7.44031C107.579 7.44031 107.182 7.77729 107.182 8.2834C107.182 8.78951 107.465 9.05146 108.069 9.21995' fill='#1A396E' />
        <path d='M116.116 9.21931C116.569 9.35029 116.985 9.42532 117.495 9.57537C119.119 10.0624 119.913 10.8489 119.913 12.666C119.913 14.7076 118.213 15.7758 116.23 15.7758C113.434 15.7758 112.565 14.1462 112.622 12.4041H115.04C115.04 13.1162 115.285 13.7527 116.268 13.7527C116.929 13.7527 117.458 13.4341 117.458 12.8536C117.458 12.2731 117.156 12.0105 116.456 11.8236C115.909 11.6735 115.474 11.5801 114.926 11.3741C113.529 10.8686 112.829 9.93142 112.829 8.41438C112.829 6.72884 114.265 5.49219 116.286 5.49219C118.421 5.49219 119.686 6.55972 119.705 8.65789H117.382C117.363 7.88983 117.004 7.44031 116.229 7.44031C115.625 7.44031 115.228 7.77729 115.228 8.2834C115.228 8.78951 115.511 9.05146 116.116 9.21995' fill='#1A396E' />
        <path d='M57.9093 26.4325H60.7615L59.326 22.5363L57.9093 26.4325ZM61.5743 28.6433H57.0972L55.8124 32.2025H53.2246L57.9472 19.2402H60.7047L65.6538 32.2025H62.8767L61.5737 28.6433H61.5743Z' fill='#1A396E' />
        <path d='M70.9817 24.7658H70.3393C69.3568 24.7658 69.0174 25.2344 69.0174 26.0959V32.2022H66.4863V22.1806H69.0174V22.9862C69.3764 22.4807 69.9431 22.1431 70.9817 22.1431V24.7658Z' fill='#1A396E' />
        <path d='M75.1749 22.1812H76.724V24.2978H75.1749V28.9621C75.1749 29.7111 75.4203 30.0297 76.1384 30.0297H76.724V32.1654C76.6672 32.1654 76.2142 32.2963 75.3453 32.2963C73.4371 32.2963 72.6439 31.3413 72.6439 29.6736V24.2972H71.6992V22.1806H72.6439V20.6069H75.1749V22.1806V22.1812Z' fill='#1A396E' />
        <path d='M57.7497 40.7634C58.3732 41.0253 58.9966 41.2504 59.7141 41.5689C61.2822 42.262 62.2262 43.3486 62.2262 45.1842C62.2262 47.6568 60.4505 49.2114 57.9195 49.2114C54.4627 49.2114 53.6317 46.9822 53.7257 44.9781H56.2195C56.2006 46.0272 56.6347 46.9638 57.9195 46.9638C58.8452 46.9638 59.5248 46.4392 59.5248 45.4652C59.5248 44.6037 58.9771 44.1541 58.1271 43.7981C57.39 43.4611 56.6158 43.236 55.8604 42.8056C54.5946 42.1501 53.9144 40.97 53.9144 39.4154C53.9144 37.2988 55.4635 35.7627 57.9188 35.7627C60.8657 35.7627 61.9044 37.5233 61.8103 39.6405H59.3923C59.3923 38.6849 58.8824 37.9919 57.9188 37.9919C57.0121 37.9919 56.5211 38.5539 56.5211 39.2845C56.5211 39.9591 56.8991 40.4086 57.7491 40.7646' fill='#1A396E' />
        <path d='M65.8531 42.8799H70.4243C70.3107 41.7558 69.4607 40.8943 68.0819 40.8943C66.7972 40.8943 65.985 41.7374 65.8531 42.8799ZM65.7964 44.7721C65.9289 46.1022 66.8729 46.9078 68.3084 46.9078C69.5932 46.9078 70.3296 46.2332 70.8584 45.4092L72.7288 46.5333C72.1242 47.7884 70.7644 49.137 68.3084 49.137C65.248 49.137 63.1328 47.1704 63.1328 43.9856C63.1328 40.9884 65.1729 38.7783 68.1008 38.7783C71.0288 38.7783 73.0499 40.7449 73.0499 43.7421C73.0499 43.9856 73.0499 44.3417 73.0121 44.7721H65.7964Z' fill='#1A396E' />
        <path d='M78.679 41.5314H78.0366C77.0541 41.5314 76.7146 42 76.7146 42.8615V48.9679H74.1836V38.9462H76.7146V39.7518C77.0737 39.2463 77.6403 38.9087 78.679 38.9087V41.5314Z' fill='#1A396E' />
        <path d='M84.3277 45.8213L86.6133 38.9463H89.2389L85.6686 48.968H82.8921L79.2461 38.9463H82.0422L84.3277 45.8213Z' fill='#1A396E' />
        <path d='M92.5838 48.968H90.0521V38.9463H92.5838V48.968ZM89.8066 36.2988C89.8066 35.4557 90.4869 34.7817 91.3179 34.7817C92.149 34.7817 92.8292 35.4563 92.8292 36.2988C92.8292 37.1412 92.1679 37.7783 91.3179 37.7783C90.468 37.7783 89.8066 37.1228 89.8066 36.2988Z' fill='#1A396E' />
        <path d='M101.141 46.0458L102.538 47.7313C101.934 48.3684 100.744 49.1365 99.0059 49.1365C95.8135 49.1365 93.7734 46.8889 93.7734 43.9851C93.7734 41.0814 95.965 38.7778 98.9869 38.7778C100.819 38.7778 101.934 39.5084 102.538 40.1264L101.084 41.8685C100.668 41.4749 100.007 41.0445 99.0059 41.0445C97.3438 41.0445 96.3045 42.2996 96.3045 43.9667C96.3045 45.7648 97.5703 46.8704 99.0248 46.8704C100.045 46.8704 100.687 46.4209 101.141 46.0464' fill='#1A396E' />
        <path d='M105.239 42.8799H109.81C109.697 41.7558 108.847 40.8943 107.468 40.8943C106.183 40.8943 105.371 41.7374 105.239 42.8799M105.182 44.7721C105.314 46.1022 106.259 46.9078 107.695 46.9078C108.979 46.9078 109.716 46.2332 110.245 45.4092L112.115 46.5333C111.511 47.7884 110.151 49.137 107.695 49.137C104.635 49.137 102.52 47.1704 102.52 43.9856C102.52 40.9884 104.56 38.7783 107.488 38.7783C110.415 38.7783 112.437 40.7449 112.437 43.7421C112.437 43.9856 112.437 44.3417 112.399 44.7721H105.183H105.182Z' fill='#1A396E' />
        <path d='M56.1744 53.3125C57.3078 53.3125 58.0265 53.975 58.1584 54.9287H56.9235C56.8528 54.5873 56.5796 54.3768 56.1744 54.3768C55.5977 54.3768 55.2431 54.8989 55.2431 55.6618C55.2431 56.4248 55.5971 56.9366 56.1744 56.9366C56.5796 56.9366 56.8528 56.7255 56.9235 56.3847H58.1584C58.0271 57.3486 57.3084 58.001 56.1744 58.001C54.889 58.001 53.998 57.0473 53.998 55.6625C53.998 54.2777 54.889 53.3138 56.1744 53.3138V53.3125Z' fill='#1A396E' />
        <path d='M60.8711 58.0002C59.5554 58.0002 58.6543 57.0363 58.6543 55.6617C58.6543 54.2871 59.5554 53.313 60.8711 53.313C62.1868 53.313 63.0878 54.2769 63.0878 55.6617C63.0878 57.0465 62.1868 58.0002 60.8711 58.0002ZM60.8711 54.3665C60.2438 54.3665 59.8993 54.9082 59.8993 55.661C59.8993 56.4138 60.2432 56.946 60.8711 56.946C61.4989 56.946 61.8428 56.4043 61.8428 55.661C61.8428 54.9178 61.4989 54.3665 60.8711 54.3665Z' fill='#1A396E' />
        <path d='M63.8867 53.413H65.1418V53.8243C65.3848 53.5433 65.8303 53.3125 66.3565 53.3125C67.3788 53.3125 67.9555 53.975 67.9555 54.9688V57.8999H66.7004V55.2301C66.7004 54.7583 66.4575 54.4373 65.9615 54.4373C65.5766 54.4373 65.2428 54.668 65.1418 55.0597V57.9005H63.8867V53.4136V53.413Z' fill='#1A396E' />
        <path d='M68.998 53.413H70.2531V53.8243C70.4961 53.5433 70.9416 53.3125 71.4679 53.3125C72.4901 53.3125 73.0669 53.975 73.0669 54.9688V57.8999H71.8118V55.2301C71.8118 54.7583 71.5688 54.4373 71.0728 54.4373C70.6879 54.4373 70.3541 54.668 70.2531 55.0597V57.9005H68.998V53.4136V53.413Z' fill='#1A396E' />
        <path d='M78.0269 56.4342C77.8647 57.3879 77.1567 58.0002 76.0228 58.0002C74.7071 58.0002 73.8262 57.0465 73.8262 55.6617C73.8262 54.2769 74.7374 53.313 76.0026 53.313C77.2678 53.313 78.0572 54.2266 78.0572 55.5415V55.923H75.0409C75.0813 56.5957 75.4662 56.9969 76.0228 56.9969C76.4481 56.9969 76.7617 56.8163 76.8626 56.4348H78.0269V56.4342ZM75.0712 55.1696H76.8223C76.8122 54.6978 76.5288 54.3265 76.0127 54.3265C75.5268 54.3265 75.1829 54.6177 75.0712 55.1696Z' fill='#1A396E' />
        <path d='M80.7897 53.3125C81.923 53.3125 82.6417 53.975 82.7736 54.9287H81.5387C81.468 54.5873 81.1948 54.3768 80.7897 54.3768C80.2129 54.3768 79.8583 54.8989 79.8583 55.6618C79.8583 56.4248 80.2123 56.9366 80.7897 56.9366C81.1948 56.9366 81.468 56.7255 81.5387 56.3847H82.7736C82.6423 57.3486 81.9236 58.001 80.7897 58.001C79.5043 58.001 78.6133 57.0473 78.6133 55.6625C78.6133 54.2777 79.5043 53.3138 80.7897 53.3138V53.3125Z' fill='#1A396E' />
        <path d='M83.8564 54.4166H83.168V53.4126H83.8564V52.1982H85.1014V53.4126H86.0934V54.4166H85.1014V56.3838C85.1014 56.7354 85.2939 56.8956 85.6176 56.8956C85.81 56.8956 86.0227 56.8352 86.1641 56.7449V57.8493C85.9918 57.9396 85.6883 57.9899 85.3545 57.9899C84.4332 57.9899 83.8564 57.5384 83.8564 56.5447V54.4166Z' fill='#1A396E' />
        <path d='M93.319 56.2741L94.1286 53.4136H95.3433L93.9671 57.9005H92.8129L92.074 55.15L91.3351 57.9005H90.1809L88.8047 53.4136H90.0598L90.8694 56.2741L91.6083 53.4136H92.57L93.319 56.2741Z' fill='#1A396E' />
        <path d='M96.6494 52.8308C96.2443 52.8308 95.9004 52.5396 95.9004 52.118C95.9004 51.6965 96.2443 51.4053 96.6494 51.4053C97.0545 51.4053 97.3883 51.7066 97.3883 52.118C97.3883 52.5294 97.0444 52.8308 96.6494 52.8308ZM97.2766 57.8995H96.0215V53.4125H97.2766V57.8995Z' fill='#1A396E' />
        <path d='M98.6435 54.4166H97.9551V53.4126H98.6435V52.1982H99.8885V53.4126H100.88V54.4166H99.8885V56.3838C99.8885 56.7354 100.081 56.8956 100.405 56.8956C100.597 56.8956 100.81 56.8352 100.951 56.7449V57.8493C100.779 57.9396 100.475 57.9899 100.142 57.9899C99.2203 57.9899 98.6435 57.5384 98.6435 56.5447V54.4166Z' fill='#1A396E' />
        <path d='M101.863 51.3755H103.118V53.8246C103.361 53.5436 103.807 53.3128 104.333 53.3128C105.355 53.3128 105.932 53.9753 105.932 54.9691V57.9002H104.677V55.2304C104.677 54.7586 104.434 54.4376 103.938 54.4376C103.553 54.4376 103.219 54.6684 103.118 55.06V57.9008H101.863V51.3768V51.3755Z' fill='#1A396E' />
        <path d='M111.629 57.8995V57.6586C111.477 57.8093 111.022 57.9797 110.526 57.9797C109.615 57.9797 108.846 57.4577 108.846 56.5243C108.846 55.671 109.615 55.0886 110.597 55.0886C110.992 55.0886 111.447 55.219 111.629 55.3493V55.018C111.629 54.6366 111.396 54.3256 110.89 54.3256C110.536 54.3256 110.324 54.4865 110.222 54.7472H109.027C109.159 53.934 109.898 53.3115 110.931 53.3115C112.135 53.3115 112.834 53.9639 112.834 55.0378V57.8983H111.629V57.8995ZM111.629 56.3539C111.518 56.1129 111.174 55.9622 110.809 55.9622C110.415 55.9622 110.01 56.1326 110.01 56.5141C110.01 56.8956 110.415 57.066 110.809 57.066C111.174 57.066 111.518 56.9153 111.629 56.6743V56.3532V56.3539Z' fill='#1A396E' />
        <path d='M116.598 54.6374C116.426 54.5369 116.203 54.4867 115.971 54.4867C115.545 54.4867 115.201 54.7073 115.111 55.1193V57.8997H113.855V53.4128H115.111V53.8547C115.303 53.5336 115.677 53.313 116.123 53.313C116.335 53.313 116.528 53.353 116.599 53.3931V54.638L116.598 54.6374Z' fill='#1A396E' />
        <path d='M117.692 54.4166H117.004V53.4126H117.692V52.1982H118.937V53.4126H119.929V54.4166H118.937V56.3838C118.937 56.7354 119.13 56.8956 119.454 56.8956C119.646 56.8956 119.859 56.8352 120 56.7449V57.8493C119.828 57.9396 119.524 57.9899 119.19 57.9899C118.269 57.9899 117.692 57.5384 117.692 56.5447V54.4166Z' fill='#1A396E' />
        <path d='M9.35388 40.8559H9.3482C9.34 40.7993 9.33306 40.7408 9.32486 40.6829C9.26365 40.3167 9.22011 39.9702 9.18855 39.6402C8.2029 31.6086 8.62568 22.6208 9.93505 16.1069H9.9584C10.7024 12.9673 12.4345 9.46586 18.0942 8.82369C20.1551 8.58971 21.9901 8.53313 23.6295 8.59416C28.1659 8.68445 32.4985 9.81174 35.5463 11.7039C38.3928 13.4715 39.8202 15.6434 39.7887 18.16L48.4122 18.2655C48.481 12.7219 45.5373 7.8172 40.1225 4.45567C35.6561 1.68289 29.7567 0.113075 23.5108 0.0367774C20.0711 -0.00518616 17.106 0.214805 14.5548 0.701836C14.4891 0.717732 14.4267 0.733627 14.3591 0.748886C4.44265 2.9844 2.02268 11.6677 1.18469 15.9785C0.527793 19.7387 0.135928 23.991 0.0488475 28.4601C-0.0224578 32.1402 0.123939 35.7554 0.469739 39.157C0.8351 42.5344 1.36074 46.0422 2.68841 48.7355L9.35388 40.8565V40.8559Z' fill='#1A396E' />
        <path d='M2.68185 48.7923L2.6604 48.7491C1.32705 46.0443 0.804566 42.5423 0.438574 39.1604C0.0883575 35.7181 -0.0529911 32.1181 0.0176832 28.4597C0.104133 23.9944 0.486532 19.7936 1.15352 15.9736C1.80915 12.6013 2.8516 9.85266 4.34144 7.56883C6.6718 3.99493 10.0402 1.69011 14.3526 0.717959C14.3961 0.708422 14.4371 0.698249 14.4788 0.688076L14.5482 0.671545C17.1177 0.180698 20.0494 -0.0373849 23.5119 0.00521445C29.7634 0.0815118 35.6685 1.6526 40.1405 4.42919C45.5648 7.79709 48.5142 12.7113 48.4454 18.267V18.2988L39.7581 18.1926V18.1608C39.7897 15.6564 38.3674 13.4933 35.5309 11.7321C32.497 9.84885 28.1593 8.71647 23.6299 8.62682C21.8762 8.56133 20.0154 8.63826 18.099 8.85571C12.2141 9.52331 10.6529 13.3185 9.99034 16.1154L9.98466 16.1402H9.96257C8.57496 23.0591 8.291 32.059 9.22112 39.6366C9.25394 39.9819 9.3 40.3322 9.35742 40.6781C9.36121 40.7054 9.365 40.7328 9.36815 40.7595C9.37068 40.7798 9.37383 40.8008 9.37636 40.8211L9.4281 40.8135L9.37951 40.8771L2.68312 48.7929L2.68185 48.7923ZM22.6783 0.0637091C19.5793 0.0637091 16.9171 0.2837 14.5615 0.733219L14.4939 0.74975C14.4523 0.759923 14.4106 0.770096 14.3665 0.780269C10.0705 1.74925 6.71534 4.04453 4.39381 7.60316C2.90902 9.88 1.8691 12.6216 1.21536 15.9851C0.549634 19.8012 0.167235 23.9988 0.0807853 28.461C0.010111 32.1169 0.15146 35.7143 0.501676 39.154C0.865144 42.5118 1.38258 45.9884 2.69574 48.6785L9.31578 40.8536C9.31199 40.8256 9.3082 40.797 9.30442 40.769C9.30063 40.7423 9.29748 40.7156 9.29369 40.6889C9.23627 40.3424 9.1902 39.9908 9.15739 39.6449C8.22537 32.0527 8.51186 23.0311 9.90452 16.1027L9.90957 16.0766H9.93355C10.6037 13.2644 12.1838 9.46418 18.0908 8.79404C20.0103 8.57595 21.8744 8.49902 23.6305 8.56451C28.1701 8.65479 32.5197 9.78972 35.5631 11.6793C38.4077 13.4456 39.8402 15.6157 39.8212 18.1309L48.3817 18.2358C48.4391 12.7164 45.501 7.8346 40.1064 4.48514C35.6451 1.713 29.7508 0.145093 23.5106 0.0687956C23.2298 0.0656165 22.9521 0.0637091 22.6776 0.0637091H22.6783Z' fill='#1A396E' />
        <path d='M35.9512 31.2322C34.4986 29.9848 32.7967 28.9929 30.9674 28.2999C30.9491 28.305 30.9301 28.3088 30.9118 28.3132C29.6044 28.8244 27.2021 29.8449 24.4622 29.8207C21.5626 29.7953 20.8477 28.832 20.8565 28.1441C20.8565 28.1307 20.8578 28.118 20.8584 28.1047L12.0632 27.9019C12.0248 30.9919 13.4767 33.6375 16.1523 35.3504C18.3223 36.7396 21.2597 37.467 24.8837 37.5115C30.1836 37.5764 34.8797 36.4325 38.6557 34.342C37.9723 33.2427 37.0586 32.1821 35.9518 31.2316' fill='url(#paint0_linear_4844_6222)' />
        <path d='M25.3043 37.546C25.1648 37.546 25.0247 37.5454 24.884 37.5435C21.2537 37.499 18.3107 36.7704 16.1355 35.3779C13.4505 33.6587 11.9935 31.0035 12.032 27.9021V27.8696L20.8915 28.0737L20.8896 28.1201C20.8896 28.1284 20.889 28.1367 20.8884 28.1449C20.8858 28.3382 20.942 28.6301 21.2247 28.9206C21.6002 29.3072 22.4672 29.772 24.4631 29.7891C27.0307 29.812 29.2948 28.9174 30.6484 28.3834C30.7367 28.3484 30.8213 28.3153 30.9014 28.2835L30.9304 28.2765C30.9405 28.274 30.9506 28.2721 30.9607 28.2689L30.9702 28.2664L30.9797 28.2702C32.8456 28.9772 34.526 29.9653 35.9729 31.2076C37.0722 32.1512 37.984 33.2003 38.6832 34.3257L38.7008 34.3543L38.6718 34.3708C34.9128 36.4518 30.2988 37.5467 25.3043 37.5467V37.546ZM12.0957 27.9345C12.068 30.9997 13.5136 33.6231 16.1702 35.3239C18.3353 36.71 21.267 37.4354 24.8853 37.4799C30.018 37.5429 34.7633 36.4537 38.6125 34.3307C37.919 33.2219 37.0173 32.1874 35.9325 31.256C34.4938 30.0206 32.8229 29.037 30.9677 28.3331C30.9601 28.335 30.9532 28.337 30.9456 28.3382L30.9216 28.3439C30.8453 28.3738 30.7607 28.4075 30.6724 28.4425C29.3314 28.9715 27.0976 29.8527 24.559 29.8527C24.5275 29.8527 24.4953 29.8527 24.4637 29.8527C22.4464 29.8349 21.5648 29.36 21.1805 28.9651C20.8833 28.6593 20.824 28.3497 20.8265 28.1443C20.8265 28.1418 20.8265 28.1386 20.8265 28.136L12.0957 27.9351V27.9345Z' fill='url(#paint1_linear_4844_6222)' />
        <path d='M20.8565 28.1443C20.8672 27.2643 21.7021 26.544 24.1416 26.6769C26.2258 26.79 28.69 27.5263 30.0063 27.9669C32.1997 28.6561 34.2474 29.7682 35.9518 31.2318C38.5604 33.4711 40.0982 36.3196 40.0667 38.8507C40.0244 42.2479 38.6727 44.767 35.9335 46.5517C33.1892 48.3402 29.1449 49.2939 24.5467 49.238C24.1283 49.2329 23.7226 49.2246 23.3282 49.2145C22.3596 49.2145 19.5825 49.1592 16.1011 48.6015C10.7488 47.7445 9.56184 42.0349 9.35361 40.8555L2.6875 48.7351C2.87744 49.1204 3.08315 49.4904 3.30779 49.8395C5.46967 53.1985 9.43185 55.8924 13.5625 56.8404C16.5605 57.5283 20.228 57.7382 24.4407 57.7897C30.831 57.8679 36.4433 56.4532 40.6699 53.6989C45.8386 50.3303 48.6119 45.233 48.6902 38.9563C48.7539 33.8634 46.168 28.6911 41.5963 24.7662C37.0586 20.8706 31.2065 18.6841 25.1184 18.609C16.7151 18.506 12.12 23.3204 12.0626 27.9021' fill='#1A396E' />
        <path d='M24.9425 57.8248C24.7759 57.8248 24.6087 57.8236 24.4408 57.8216C19.4469 57.7606 16.1921 57.4764 13.5563 56.8717C9.38843 55.9155 5.45149 53.2279 3.28204 49.8575C3.06623 49.5224 2.85673 49.1492 2.65986 48.7499L2.65039 48.7308L2.66364 48.7149L9.37266 40.7844L9.38465 40.8506C9.66734 42.4541 10.9868 47.751 16.1057 48.5706C19.5554 49.1231 22.3029 49.1829 23.3258 49.1829H23.3277C23.7372 49.1937 24.1468 49.2013 24.5468 49.2064C29.1407 49.263 33.1767 48.3105 35.9159 46.5252C38.6451 44.7468 39.9923 42.236 40.0346 38.8503C40.0661 36.3287 38.5321 33.4891 35.9304 31.2555C34.2557 29.8173 32.2036 28.6907 29.9963 27.997C28.7046 27.564 26.2266 26.8214 24.1392 26.7082C22.7251 26.6313 21.7546 26.8316 21.2535 27.304C21.015 27.529 20.8913 27.812 20.8875 28.1445H20.8244C20.8288 27.7929 20.9588 27.4953 21.2106 27.2576C21.7249 26.7731 22.7118 26.5671 24.143 26.6446C26.238 26.7584 28.7217 27.503 30.0159 27.9366C32.2308 28.6328 34.2904 29.7639 35.9715 31.2072C38.587 33.4529 40.1292 36.3102 40.0977 38.8509C40.0554 42.2595 38.6987 44.7869 35.9506 46.578C33.2013 48.3697 29.1507 49.3259 24.5468 49.2693C24.1468 49.2643 23.7366 49.2566 23.3277 49.2458H23.3252C22.301 49.2458 19.5504 49.1861 16.0962 48.6329C11.0045 47.8178 9.64337 42.6162 9.3348 40.9275L2.72548 48.7397C2.91857 49.1301 3.12366 49.4938 3.33442 49.8219C5.49503 53.179 9.41683 55.8564 13.5696 56.8088C16.2009 57.4122 19.452 57.6958 24.4408 57.7568C30.8261 57.835 36.4315 56.4222 40.6524 53.6711C45.8116 50.3089 48.5805 45.2199 48.6581 38.9552C48.7212 33.8719 46.1397 28.7085 41.5756 24.79C37.0436 20.8988 31.1984 18.7148 25.1179 18.6404C16.731 18.5374 12.151 23.3352 12.0943 27.9016H12.0311C12.0886 23.3034 16.6944 18.4738 25.1185 18.5768C31.2142 18.6512 37.0732 20.8409 41.6166 24.7416C46.1953 28.6729 48.785 33.8541 48.7212 38.9565C48.643 45.244 45.8646 50.3509 40.6864 53.7251C36.5659 56.4108 31.1303 57.8242 24.9418 57.8242L24.9425 57.8248Z' fill='#1A396E' />
        <path d='M39.7898 18.1606C39.7715 19.6325 39.4269 21.1718 38.6836 22.6126C39.7027 23.2586 40.6776 23.977 41.5976 24.7667C42.9695 25.9449 44.1615 27.2356 45.1579 28.6026C47.2062 25.6931 48.3647 22.1885 48.4139 18.2662L39.7904 18.1606H39.7898Z' fill='url(#paint2_linear_4844_6222)' />
        <path d='M45.1585 28.657L45.1326 28.6214C44.1268 27.241 42.9304 25.9522 41.5775 24.7906C40.6719 24.013 39.6926 23.2894 38.6672 22.639L38.6426 22.6231L38.6558 22.597C39.3582 21.237 39.7393 19.7022 39.7589 18.1597V18.1279L48.4461 18.2341V18.2659C48.3975 22.131 47.3002 25.6146 45.1837 28.6201L45.1585 28.6557V28.657ZM38.7252 22.6015C39.7443 23.2494 40.718 23.9698 41.6185 24.7429C42.9632 25.8975 44.1539 27.1781 45.1579 28.5489C47.244 25.5701 48.3288 22.1221 48.3824 18.2983L39.822 18.1934C39.798 19.7257 39.4194 21.2485 38.7259 22.6015H38.7252Z' fill='url(#paint3_linear_4844_6222)' />
      </g>
      <defs>
        <linearGradient id='paint0_linear_4844_6222' x1='21.2351' y1='38.319' x2='28.1699' y2='26.3984' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#1A396E' />
          <stop offset='0.47' stopColor='#1A396E' />
          <stop offset='0.7' stopColor='#1D3C70' stopOpacity='0.98' />
          <stop offset='0.79' stopColor='#2C4879' stopOpacity='0.92' />
          <stop offset='0.85' stopColor='#445D89' stopOpacity='0.81' />
          <stop offset='0.9' stopColor='#677C9F' stopOpacity='0.66' />
          <stop offset='0.94' stopColor='#95A4BC' stopOpacity='0.46' />
          <stop offset='0.97' stopColor='#CDD4DF' stopOpacity='0.22' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient id='paint1_linear_4844_6222' x1='21.2209' y1='38.3472' x2='28.1882' y2='26.3702' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#1A396E' />
          <stop offset='0.47' stopColor='#1A396E' />
          <stop offset='0.7' stopColor='#1D3C70' stopOpacity='0.98' />
          <stop offset='0.79' stopColor='#2C4879' stopOpacity='0.92' />
          <stop offset='0.85' stopColor='#445D89' stopOpacity='0.81' />
          <stop offset='0.9' stopColor='#677C9F' stopOpacity='0.66' />
          <stop offset='0.94' stopColor='#95A4BC' stopOpacity='0.46' />
          <stop offset='0.97' stopColor='#CDD4DF' stopOpacity='0.22' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient id='paint2_linear_4844_6222' x1='44.1905' y1='17.5159' x2='42.3105' y2='28.0967' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#1A396E' />
          <stop offset='0.3' stopColor='#1F3D71' stopOpacity='0.98' />
          <stop offset='0.48' stopColor='#304C7C' stopOpacity='0.9' />
          <stop offset='0.62' stopColor='#4E668F' stopOpacity='0.77' />
          <stop offset='0.75' stopColor='#788AA9' stopOpacity='0.59' />
          <stop offset='0.87' stopColor='#AFBACC' stopOpacity='0.35' />
          <stop offset='0.98' stopColor='#F1F3F6' stopOpacity='0.06' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient id='paint3_linear_4844_6222' x1='44.1956' y1='17.48' x2='42.3001' y2='28.1491' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#1A396E' />
          <stop offset='0.3' stopColor='#1F3D71' stopOpacity='0.98' />
          <stop offset='0.48' stopColor='#304C7C' stopOpacity='0.9' />
          <stop offset='0.62' stopColor='#4E668F' stopOpacity='0.77' />
          <stop offset='0.75' stopColor='#788AA9' stopOpacity='0.59' />
          <stop offset='0.87' stopColor='#AFBACC' stopOpacity='0.35' />
          <stop offset='0.98' stopColor='#F1F3F6' stopOpacity='0.06' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <clipPath id='clip0_4844_6222'>
          <rect width='120' height='58' fill='white' />
        </clipPath>
      </defs>
    </svg>

  )
}
