
import style from './style.module.css'
import { hasValue } from '@root/misc/helpers'
import { imageUrl } from '@root/misc/image-utils'

interface Props {
  src: string | null | undefined
  size: 'small' | 'medium' | 'large'
  alt: string
  onClick?: () => any
}

export const missingImageText = (): string => 'Geen afbeelding beschikbaar'

export const ArtImage = (props: Props): JSX.Element => {
  const atrs = {
    src: imageUrl(props.src, props.size),
    alt: props.alt,
    ...(hasValue(props.onClick) && { onClick: props.onClick })
  }

  const classes = (props.size === 'small')
    ? style.placeholder + ' ' + style.small
    : style.placeholder

  return hasValue(props.src) && props.src !== ''
    ? <img {...atrs} />
    : <div className={classes}>{props.size !== 'small' && missingImageText()}</div>
}
