import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import style from './style.module.css'
import { Logo } from '@components/atoms/Logo/Component'
import { SegmentedController } from '@components/molecules/SegmentedController/Component'
import useWindowSize from '@root/misc/use-window-size'
import { Link } from 'react-router-dom'
import { SwirlComponent } from '@root/components/atoms/Swirl/Component'
import { hasValue } from '@root/misc/helpers'

const SideBox = (props: React.PropsWithChildren<{ layout: 'row' | 'column', flex?: string, hasSwirl?: boolean }>): JSX.Element => {
  return (
    <Box
      width={props.layout === 'row' ? '50vw' : '100vw'}
      maxHeight='100vh'
      height={props.layout === 'row' ? '100vh' : 'auto'}
      flex={props.flex !== undefined ? props.flex : undefined}
      position='relative'
    >
      {
        hasValue(props.hasSwirl) && props.hasSwirl &&
        (<div className={style.swirl}><SwirlComponent /></div>)
      }

      {props.children}
    </Box>
  )
}

export const LoginPage = (): JSX.Element => {
  const [value, setValue] = React.useState<number>(0)
  const windowSize = useWindowSize()
  const isTabletAndAbove = windowSize.isTabletAndAbove

  return (
    <Stack
      direction={isTabletAndAbove ? 'row' : 'column'}
      maxHeight='100vh'
      height='100vh'
      justifyContent='space-between'
      alignItems='flex-start'
      flexWrap='nowrap'
      spacing={0}
    >
      <SideBox
        layout={isTabletAndAbove ? 'row' : 'column'}
        hasSwirl
      >
        <Box sx={{ display: 'grid', placeItems: 'center' }} width='100%' height='100%'>
          <Box
            width='370px'
            maxWidth='calc(100vw - 32px)'
            paddingBlock='32px'
          >
            <Stack
              spacing='40px'
            >
              <Logo />

              <SegmentedController
                ariaLabel='Login'
                componentId='login_segmented_controller'
                setValue={setValue}
                value={value}
                forceEqualHeight
                tabs={[
                  {
                    label: 'Klanten',
                    component: (
                      <Stack
                        spacing='40px'
                      >
                        <Box>
                          <Typography variant='h2' pb='8px'>Log in bij Mijn Bussiness Art Service</Typography>
                          <Typography variant='body1'>Klanten kunnen inloggen in de Mijn-omgeving via een unieke inloglink. Deze link is verstrekt door Business Art Service. Heb je geen toegang tot de inloglink? Neem dan contact met ons op.</Typography>
                        </Box>
                        <Stack
                          spacing='8px'
                        >
                          <Button variant='contained' component={Link} to='mailto:info@businessartservice.nl'>Neem contact op</Button>
                          <Button variant='text' component={Link} to='https://businessartservice.nl' target='_blank'>Ga naar de website</Button>
                        </Stack>
                      </Stack>
                    )
                  }
                  // {
                  //   label: 'Medewerkers Business Art Service',
                  //   component: (
                  //     <Stack
                  //       spacing='40px'
                  //     >
                  //       <Box>
                  //         <Typography variant='h2' pb='8px'>Log in bij Mijn Bussiness Art Service</Typography>
                  //         <Typography variant='body1'>Klik op onderstaande knop om in te loggen in de Mijn-omgeving.</Typography>
                  //       </Box>
                  //       <Stack
                  //         spacing='8px'
                  //       >
                  //         <Button variant='contained'>Inloggen via Office 365</Button>
                  //       </Stack>
                  //     </Stack>
                  //   )
                  // }
                ]}
              />

            </Stack>
          </Box>
        </Box>
      </SideBox>
      <SideBox
        layout={isTabletAndAbove ? 'row' : 'column'}
        flex={isTabletAndAbove ? undefined : '1'}
      >
        {/* logic here is for setting background images between tabs */}
        <img src={value === 0 ? 'images/login_banner.jpeg' : 'images/login_banner.jpeg'} className={style['login-image']} alt='Login page' />
      </SideBox>
    </Stack>
  )
}
