import * as React from 'react'
import {
  createBrowserRouter,
  RouterProvider
  // useRouteError
} from 'react-router-dom'

import CustomerSitesPage from '@root/components/organisms/pages/CustomerSitesPage/Component'
import CustomerSitePage from '@root/components/organisms/pages/CustomerSitePage/Component'
import { LayoutNonAuth } from '@components/templates/non-auth/Component'
import { LayoutAuth } from '@components/templates/auth/Component'
import { LoginPage } from '@components/organisms/pages/LoginPage/Component'

const router = createBrowserRouter([
  {
    path: '/',
    element: <LayoutAuth><CustomerSitesPage /></LayoutAuth>
  },
  {
    path: '/verhuurorder/:id',
    element: <LayoutAuth><CustomerSitePage /></LayoutAuth>
  },
  {
    path: '/login',
    element: <LayoutNonAuth><LoginPage /></LayoutNonAuth>
  },
  {
    path: '*',
    element: <p>Not found</p>
  }
])

const Router = (): JSX.Element => <RouterProvider router={router} />

// function ErrorBoundary(): JSX.Element {
//   const error = useRouteError()
//   console.error(error)
//   // Uncaught ReferenceError: path is not defined
//   return <div>Helaas, deze pagina bestaat helaas niet (meer) of je hebt nog geen toegang tot deze pagina !</div>
// }

export default Router
