import React from 'react'
import style from './style.module.css'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import useWindowSize from '@root/misc/use-window-size'
import { Drawer, Box } from '@mui/material'
import { hasValue } from '@root/misc/helpers'
import { defaultTransition } from '@root/misc/constants'

interface Props {
  startSideBar?: JSX.Element
  startSideBarWidth?: number
  startSideBarOpen?: boolean
  endSideBar?: JSX.Element
  endSideBarWidth?: number
  endSideBarOpen?: boolean
  setStartSideBarOpen?: React.Dispatch<React.SetStateAction<boolean>>
  setEndSideBarOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

export const SidebarHandler = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const windowSize = useWindowSize()

  const startCloseHandler = (): void => {
    if (hasValue(props.setStartSideBarOpen)) {
      props.setStartSideBarOpen(false)
    }
  }

  const endCloseHandler = (): void => {
    if (hasValue(props.setEndSideBarOpen)) {
      props.setEndSideBarOpen(false)
    }
  }

  return (
    <div className={style.flex}>
      {!windowSize.isLargeDesktopAndAbove && (
        <Drawer
          variant='temporary'
          anchor='left'
          open={props.startSideBarOpen}
          onClose={startCloseHandler}
        >
          <Box
            sx={{ width: windowSize.isMobile ? '100vw' : props.startSideBarWidth, maxWidth: '100vw' }}
          >
            {props.startSideBar}
          </Box>
        </Drawer>
      )}

      {
        windowSize.isLargeDesktopAndAbove && (
          <motion.div
            initial={{ width: 0, flex: 0 }}
            animate={{
              width: (props.startSideBarOpen ?? false) ? props.startSideBarWidth : 0,
              flex: (props.startSideBarOpen ?? false) ? `0 0 ${props.startSideBarWidth ?? 0}px` : '0 0 0px'
            }}
            transition={defaultTransition}
            className={clsx(style.sidebar, style.start)}
          >
            <motion.div
              initial={{ x: '-100%' }}
              animate={{ x: (props.startSideBarOpen ?? false) ? '0%' : '-100%' }}
              transition={defaultTransition}
              className={style['child-wrapper']}
              style={{ width: `${props.startSideBarWidth ?? 0}px` }}
            >
              {props.startSideBar}
            </motion.div>
          </motion.div>
        )
      }

      <div className={style.content}>
        {props.children}
      </div>

      {
        !windowSize.isLargeDesktopAndAbove && (
          <Drawer
            variant='temporary'
            anchor='left'
            open={props.endSideBarOpen}
            onClose={endCloseHandler}
          >
            <Box
              sx={{ width: windowSize.isMobile ? '100vw' : props.endSideBarWidth, maxWidth: '100vw' }}
            >
              {props.endSideBar}
            </Box>
          </Drawer>
        )
      }

      {
        windowSize.isLargeDesktopAndAbove && (
          <motion.div
            initial={{ width: 0, flex: 0 }}
            animate={{
              width: (props.endSideBarOpen ?? false) ? props.endSideBarWidth : 0,
              flex: (props.endSideBarOpen ?? false) ? `0 0 ${props.endSideBarWidth ?? 0}px` : 0
            }}
            transition={defaultTransition}
            className={clsx(style.sidebar, style.end)}
          >
            <motion.div
              initial={{ x: '100%' }}
              animate={{ x: (props.endSideBarOpen ?? false) ? 0 : '100%' }}
              transition={defaultTransition}
              className={style['child-wrapper']}
              style={{ width: `${props.endSideBarWidth ?? 0}px` }}
            >
              {props.endSideBar}
            </motion.div>
          </motion.div>
        )
      }
    </div>
  )
}
