import { getImageUrl } from './environments'

type ImageSize = 'small' | 'medium' | 'large'

/**
 * Returns the url for the image path. Takes a second argument that effects the image size
 */
export function imageUrl (path: string | null | undefined, size: ImageSize): string {
  switch (size) {
    case 'small':
      return getImageUrl() + 'tr:w-40,h-40,c-at_max/' + path

    case 'medium':
      return getImageUrl() + 'tr:w-350,h-350,c-at_max/' + path

    case 'large':
      return getImageUrl() + path

    default:
      return getImageUrl() + path
  }
}
