import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Header } from '@root/components/molecules/Header/Component'
import { Account, CustomerSite, Placement, RentalOrder, RentalOrderLine } from '@root/generated/graphql-request'
import { groupBy, hasValue, infoValue } from '@root/misc/helpers'
import { getRentalOrder, listRentalOrdersTeaser } from '@root/services/apiService'
import { Loader } from '@root/components/atoms/Loader/Component'
import { PageContent } from '@root/components/molecules/PageContent/Component'
import style from './styles.module.css'
import { SidebarHandler } from '@root/components/molecules/SidebarHandler/Component'
import { Box, Button, Drawer, InputAdornment, OutlinedInput } from '@mui/material'
import { SegmentedController } from '@root/components/molecules/SegmentedController/Component'
import { RentalOrderLineCard } from '@root/components/molecules/RentalOrderLineCard/Component'
import useWindowSize from '@root/misc/use-window-size'
import ArtObjectDetailView from '@root/components/molecules/ArtObjectDetailView/Component'
import { Filters } from '@root/components/molecules/FiltersView/Component'
import { CollapseIcon } from '@root/components/atoms/Icons/Component'
import { RentalOrderHistory } from '@root/components/molecules/RentalOrderHistory/Component'
import Search from '@mui/icons-material/Search'

export const artLocationDescription = ({ lastCustomerArtLocation: artLocation }: RentalOrderLine): string => `${infoValue(artLocation.building)} - ${infoValue(artLocation.floor)} - ${infoValue(artLocation.room)} - ${infoValue(artLocation.description)}`

const EmptySearch = (props: { searchActive: boolean }): JSX.Element => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <h3>Geen kunstwerken gevonden</h3>
    <p style={{ maxWidth: '350px', textAlign: 'center' }}>
      {(props.searchActive)
        ? 'Er zijn geen kunstwerken gevonden voor de opgegeven filters en/of zoekterm.'
        : 'Er zijn geen actieve kunstwerken gevonden in deze kunstcollectie.'}
    </p>
  </Box>
)

const artLocationsArtworkCount = (artLocations: artLocation[]): number =>
  artLocations.reduce((acc, al) => acc + al.value.length, 0)

interface artLocation {
  key: string
  value: RentalOrderLine[]
}

interface FilterObj {
  isAvailableForSale?: string
  artObjectCategory?: string[]
  numberOfUnits?: number
  mainTheme?: string[]
  status?: string
  searchTerm?: string
}

export default function Component (props: React.PropsWithChildren): JSX.Element {
  const { id } = useParams()
  const windowSize = useWindowSize()

  const [filtersOpen, setFiltersOpen] = React.useState<boolean>(false)
  const [, setFilters] = React.useState<FilterObj>({})
  const [rentalOrder, setRentalOrder] = React.useState<RentalOrder>()
  const [rentalOrdersCount, setRentalOrdersCount] = React.useState<number>(0)
  const [artLocations, setArtLocations] = React.useState<artLocation[]>([])
  const [rentalOrderHistoryLines, setRentalOrderHistoryLines] = React.useState<RentalOrderLine[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)
  const [loadingNewData, setLoadingNewData] = React.useState<boolean>(false)
  const [activeArtObject, setActiveArtObject] = React.useState<Placement | null>()
  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const [artistSearchTerm, setArtistSearchTerm] = React.useState<string>('')
  const [tabValue, setTabValue] = React.useState<number>(0)
  const [historyStatus, setHistoryStatus] = React.useState<string>('')

  const navigate = useNavigate()

  React.useEffect(() => {
    const artObjectId = new URLSearchParams(window.location.search).get('artObject')

    if (hasValue(artObjectId) && hasValue(rentalOrder?.rentalOrderLines) && !hasValue(activeArtObject)) {
      const item = rentalOrder?.rentalOrderLines.find(r => hasValue(r) && r.id === artObjectId)
      if (hasValue(item)) {
        setActiveArtObject(item?.placement)
      }
    }
  })

  const setActiveArtObjectWrapper = (artObj: Placement): void => {
    if (hasValue(rentalOrder)) {
      navigate('/verhuurorder/' + rentalOrder.id + '?artObject=' + id)
      setActiveArtObject(artObj)
    }
  }

  const fetchCustomerSites = React.useCallback(async (artist: string, term: string) => {
    setLoadingNewData(true)

    const apiCustomerSites = await listRentalOrdersTeaser()
    if (hasValue(apiCustomerSites)) {
      setRentalOrdersCount(apiCustomerSites.length)
    }

    const apiRentalOrder = await getRentalOrder(id as string, { artist, searchTerm: term })
    if (hasValue(apiRentalOrder)) {
      setRentalOrder(apiRentalOrder)

      if (hasValue(apiRentalOrder.rentalOrderLines)) {
        const filteredRentalOrderLines = apiRentalOrder.rentalOrderLines.filter((rentalOrderLine) => !hasValue(rentalOrderLine?.returnPlacement))
        const rentalOrderLines = groupBy(filteredRentalOrderLines as [], artLocationDescription)
        setArtLocations(rentalOrderLines)
      }
    }

    setLoadingNewData(false)
    setLoading(false)
  }, [setRentalOrdersCount, id])

  // Initial fetch for Rental Order
  React.useEffect(() => {
    void fetchCustomerSites(artistSearchTerm, searchTerm)
  }, [fetchCustomerSites])

  // Listener for input from searchfield
  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      void fetchCustomerSites(artistSearchTerm, searchTerm)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [fetchCustomerSites, searchTerm, artistSearchTerm])

  const closeArtObjectDrawer = (): void => {
    if (
      hasValue(rentalOrder)) {
      navigate('/verhuurorder/' + rentalOrder.id)
      setActiveArtObject(null)
    }
  }

  const filterOnChange = (newFilterObject: FilterObj): void => {
    if (hasValue(newFilterObject.searchTerm)) {
      setArtistSearchTerm(newFilterObject.searchTerm)
    }

    if (hasValue(newFilterObject.status)) {
      setHistoryStatus(newFilterObject.status)
    }
  }

  React.useEffect(() => {
    if (hasValue(rentalOrder) && hasValue(rentalOrder?.rentalOrderLines)) {
      if (historyStatus === 'Actief') {
        setRentalOrderHistoryLines(rentalOrder.rentalOrderLines.filter((rentalOrderLine) => !hasValue(rentalOrderLine?.returnPlacement)) as RentalOrderLine[])
      } else if (historyStatus === 'Geretourneerd') {
        setRentalOrderHistoryLines(rentalOrder.rentalOrderLines.filter((rentalOrderLine) => hasValue(rentalOrderLine?.returnPlacement)) as RentalOrderLine[])
      } else {
        setRentalOrderHistoryLines(rentalOrder.rentalOrderLines as RentalOrderLine[])
      }
    }
  }, [setRentalOrderHistoryLines, historyStatus, rentalOrder])

  if (loading) {
    return (<Loader />)
  }

  return (
    <>
      <Header
        type={rentalOrdersCount > 1 ? 'single-location-with-other-locations' : 'single-location'}
        customerSite={rentalOrder?.customerSite as CustomerSite}
        customerSitesLength={rentalOrdersCount}
        numberOfUnits={rentalOrder ? rentalOrder.numberOfActiveUnits : 0}
        numberOfArtObjects={rentalOrder ? rentalOrder.numberOfActiveArtObjects : 0}
        orderNumber={rentalOrder ? rentalOrder.rentalOrderNumber : "000000000"}
        account={rentalOrder?.customer as Account}
      />

      <Drawer
        variant='temporary'
        anchor='right'
        open={hasValue(activeArtObject)}
        onClose={closeArtObjectDrawer}
      >
        <Box sx={{ width: windowSize.isMobile ? '100vw' : 880, maxWidth: '100vw' }}>
          {
            hasValue(activeArtObject) && (
              <ArtObjectDetailView placement={(activeArtObject)} onClose={closeArtObjectDrawer} />
            )
          }
        </Box>
      </Drawer>

      <Button className={style['filter-button']} onClick={() => setFiltersOpen(!filtersOpen)}><CollapseIcon open={filtersOpen} /> Filters</Button>

      <SidebarHandler
        startSideBar={
          <Filters
            toggleOpenCallback={setFiltersOpen}
            onChange={filterOnChange}
            renderStatus={tabValue === 1}
            artistSearchTerm={artistSearchTerm}
            historyStatus={historyStatus}
            results={(tabValue === 0) ? artLocationsArtworkCount(artLocations) : rentalOrderHistoryLines?.length}
          />
        }
        startSideBarOpen={filtersOpen}
        startSideBarWidth={256}
        setStartSideBarOpen={setFiltersOpen}
      >
        <PageContent
          title='Jouw kunstcollectie'
          endElement={
            <Box className={style['search-box']}>
              <OutlinedInput
                onChange={(e) => setSearchTerm(e.target.value)}
                defaultValue={searchTerm}
                style={{ backgroundColor: '#fff', width: '350px' }}
                placeholder='Zoek kunstwerk, nummer of kunstenaar'
                startAdornment={
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                }
              />
            </Box>
          }
        >
          {loadingNewData
            ? (<Loader />)
            : (
              <div className={style['content-box']}>
                <SegmentedController
                  ariaLabel='Collectiekeuze'
                  componentId='collection_segmented_controller'
                  value={tabValue}
                  setValue={setTabValue}
                  tabs={[
                    {
                      label: 'Actieve collectie',
                      component: (
                        <>
                          {
                            artLocations.length > 0
                              ? artLocations.map((artLocation) => (
                                <React.Fragment key={artLocation.key}>
                                  <RentalOrderLineCard description={artLocation.key} rentalOrders={artLocation.value} setActiveArtObject={setActiveArtObjectWrapper} />
                                </React.Fragment>
                              ))
                              : (
                                <EmptySearch searchActive={hasValue(searchTerm) && searchTerm.length > 0} />
                                )
                          }
                        </>
                      )
                    },
                    {
                      label: 'Volledige huurhistorie',
                      component: hasValue(rentalOrder) && hasValue(rentalOrder.rentalOrderLines) && rentalOrder.rentalOrderLines.length > 0
                        ? (
                          <RentalOrderHistory
                            rentalOrderId={rentalOrder.id}
                            historyStatus={historyStatus}
                            rentalOrderLines={rentalOrderHistoryLines}
                          />
                          )
                        : (
                          <EmptySearch searchActive={hasValue(searchTerm) && searchTerm.length > 0} />
                          )
                    }
                  ]}
                />
              </div>
              )}
        </PageContent>
      </SidebarHandler>
      {props.children}
    </>

  )
}
