import React from 'react'

interface Props {
  children?: React.ReactNode
}

export const LayoutNonAuth = (props: Props): JSX.Element => {
  const { children } = props

  return (
    <>
      {children}
    </>
  )
}
