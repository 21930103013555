import React from 'react'
import Router from './Router'
import { THEME } from '@style/mui-theme'
import { ThemeProvider } from '@mui/material'
import { appContext, useAppValue } from '@global/context'

function App (): JSX.Element {
  return (
    <ThemeProvider theme={THEME}>
      <appContext.Provider value={useAppValue()}>
        <Router />
      </appContext.Provider>
    </ThemeProvider>
  )
}

export default App
