import { Box, Tab, Tabs } from '@mui/material'
import { hasValue } from '@root/misc/helpers'
import React from 'react'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  componentId: string
}

function CustomTabPanel (props: TabPanelProps): JSX.Element {
  const { children, value, index, componentId, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`${componentId}panel-${index}`}
      aria-labelledby={`${componentId}-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: '16px' }}>
          {children}
        </Box>
      )}
    </div>
  )
}

function a11yProps (index: number, componentId: string): { id: string, 'aria-controls': string } {
  return {
    id: `${componentId}-${index}`,
    'aria-controls': `${componentId}panel-${index}`
  }
}

interface TabProps {
  label: string
  component: JSX.Element
}

interface Props {
  ariaLabel: string
  componentId: string
  tabs: TabProps[]
  forceEqualHeight?: boolean
  value: number
  setValue: React.Dispatch<React.SetStateAction<number>>

}

export const SegmentedController = (props: Props): JSX.Element => {
  const [minHeight, setMinHeight] = React.useState<number>(0)
  const panelRefs = React.useRef<HTMLDivElement[] | null>([])

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    props.setValue(newValue)
  }

  React.useEffect(() => {
    if (minHeight === 0 && (props.forceEqualHeight ?? false)) {
      // Escape the render cycle, this is a hack to counter the fact that the DOM is not yet rendered when this useEffect is called.
      // (Caused by font loading), could be fixed by using a font observer.
      setTimeout(() => {
        let valueMinHeight = 0
        panelRefs.current?.forEach((panel: HTMLDivElement | null) => {
          if (hasValue(panel)) {
            const height = panel.getBoundingClientRect().height
            if (height > valueMinHeight) {
              valueMinHeight = height
            }
          }
        })
        setMinHeight(valueMinHeight)
      }, 10)
    }
  }, [panelRefs, minHeight, props.forceEqualHeight])

  return (
    <Box margin={0}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={props.value} onChange={handleChange} aria-label={props.ariaLabel}>
          {
            props.tabs.map((tab: TabProps, index: number) => {
              return (<Tab key={`${props.componentId}-${tab.label}`} label={tab.label} {...a11yProps(index, props.componentId)} />)
            })
          }
        </Tabs>
      </Box>
      {
        props.tabs.map((tab: TabProps, index: number) => {
          return (
            <CustomTabPanel
              key={`${props.componentId}-${tab.label}`}
              componentId={props.componentId}
              value={props.value}
              index={index}
            >
              <div
                ref={el => {
                  if (hasValue(el) && hasValue(panelRefs.current)) {
                    panelRefs.current[index] = el
                  }
                }}
                style={minHeight !== 0 && (props.forceEqualHeight ?? false) ? { height: `${minHeight}px` } : {}}
              >
                {tab.component}
              </div>
            </CustomTabPanel>
          )
        })
      }
    </Box>
  )
}
