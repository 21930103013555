import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary']
  }

  interface PaletteOptions {
    neutral?: PaletteOptions['primary']
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true
  }
}

const palette = {
  primary: {
    light: '#f5f7fb',
    main: '#a1bbe8',
    dark: '#0a1629',
    contrastText: '#fff'
  },
  neutral: {
    100: '#fff',
    200: '#EFF1F6',
    300: '00000014',
    400: '#ccc',
    500: '#949494',
    600: '#4b4b4b',
    900: '#000',
    main: '#EFF1F6',
    light: '#fff',
    dark: '#CDD5E4',
    contrastText: '#1A396E'
  }
}

export const THEME = createTheme({
  palette,
  typography: {
    fontFamily: '"Calibre", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: {
      fontFamily: '"Platform", sans-serif',
      fontWeight: 500,
      fontSize: '40px',
      lineHeight: 'auto'
    },
    h2: {
      fontFamily: '"Platform", sans-serif',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '32px'
    },
    h3: {
      fontFamily: '"Platform", sans-serif',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px'
    },
    h4: {
      fontFamily: '"Platform", sans-serif',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '22px'
    },
    h5: {
      fontFamily: '"Platform", sans-serif',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: 'auto'
    },
    body1: {
      fontFamily: '"Calibre", sans-serif',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '22px'
    },
    body2: {
      fontFamily: '"Calibre", sans-serif',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '16px'
    },
    subtitle1: {
      fontFamily: '"Calibre", sans-serif',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '14px'
    },
    button: {
      fontFamily: '"Platform", sans-serif',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '22px',
      textTransform: 'none'
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'primary',
        size: 'large',
        disableRipple: true
      },
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: '8px',
          '&:hover': {
            boxShadow: 'none'
          },
          '&.MuiButton-contained': {
            border: '1px solid rgba(0,0,0,0.8)'
          },
          '&.MuiButton-containedPrimary': {
            backgroundColor: 'var(--color-brand-primary-700)',
            '&:hover': {
              backgroundColor: 'var(--color-brand-primary-900)'
            }
          },
          '&.MuiButton-textPrimary': {
            color: 'var(--color-brand-primary-700)',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
              color: 'var(--color-brand-primary-900)'
            },

            '&.back-button': {
              color: 'var(--color-brand-primary-400)',
              padding: 0,
              '&:hover': {
                color: 'var(--color-brand-primary-700)'
              }
            }
          },
          '&.MuiButton-sizeLarge': {
            height: '40px',
            '&.MuiButton-textSizeLarge': {
              paddingTop: 0,
              paddingBottom: 0,
              height: 'auto'
            }
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 'auto',
          '& .MuiTabs-indicator': {
            backgroundColor: palette.primary.dark
          }
        }
      }
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          minHeight: 'auto',
          textAlign: 'start',
          padding: '6px 0 !important',
          marginInlineEnd: '16px !important',
          minWidth: 'auto !important',
          '&.Mui-selected': {
            color: palette.primary.dark
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiMenu-paper': {
            backgroundColor: palette.neutral[100],
            boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
            borderRadius: '12px'
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          border: '1px solid var(--neutral-300-a-8, rgba(0, 0, 0, 0.08))',
          boxShadow: 'none'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
          display: 'flex',
          flexFlow: 'row nowrap',
          alignItems: 'center',
          gap: '16px'
        }
      }
    }
  }
})
